import { IDataManagementFieldModuleSpecific, IDataManagementFieldValueTypes } from "@/interfaces/dataManagement";

export default {
    fieldsConfig: {
        // fields for filter and tabs
        factory: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-office-building-marker', displayPrio: 3, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        country: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-flag', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        profit_center_id: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: true, customHandlingIdentifier: 'PROFITCENTER_NAME', shareRouteLookup: 'profit_center_id', isReferencedObject: true, pivotResultLookup: 'profit_center' },
        general_ledger_id: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-bank-outline', displayPrio: 4, customHandling: true, customHandlingIdentifier: 'GL_NAME', shareRouteLookup: 'general_ledger_id', isReferencedObject: true, pivotResultLookup: 'general_ledger' },
        cost_center_id: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-account-cash-outline', displayPrio: 4, customHandling: true, customHandlingIdentifier: 'COSTCENTER_NAME', shareRouteLookup: 'cost_center_id', isReferencedObject: true, pivotResultLookup: 'cost_center' },
        cost_unit_id: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-boom-gate-outline', displayPrio: 4, customHandling: true, customHandlingIdentifier: 'COSTUNIT_NAME', shareRouteLookup: 'cost_unit_id', isReferencedObject: true, pivotResultLookup: 'cost_unit' },
        company_code_id: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, isSavingsProjectField: true, hierarchical: false, icon: 'mdi-format-list-bulleted-square', displayPrio: 4, customHandling: true, customHandlingIdentifier: 'COMPANYCODE_NAME', shareRouteLookup: 'company_code_id', isReferencedObject: true, pivotResultLookup: 'company_code' },
        purchasing_group: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-account-group', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        business_unit: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: true, isSavingsProjectField: true, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        business_unit_board: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        monitoring_regulation: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        monitoring_regulation_hier2: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        vendor_general_ledger: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        country_vendor: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        country_of_delivery: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        country_company_code: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        corporate_group: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        segment: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        order_type: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-map-marker-path', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        cost_type: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-map-marker-path', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        has_order_reference: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-cart', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        intercompany: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-office-building-marker-outline', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        addressable: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-office-building-marker-outline', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        touched: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-office-building-marker-outline', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        operative: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-office-building-marker-outline', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        capital_operating_expenses: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-cash', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        category_secondary_name: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, showInTab: true, showInTabSavings: true, hierarchical: false, icon: 'mdi-shape-outline', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        file_ids: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, hierarchical: false, showAsKPIField: true, showInTab: true, showInTabSavings: true, icon: 'mdi-database-outline', displayPrio: 6, customHandling: true, customHandlingIdentifier: 'EXTRACT', shareRouteLookup: 'file_ids', isReferencedObject: true, pivotResultLookup: 'file' },
        has_category: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, hierarchical: false, showAsKPIField: true, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        material_id: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showInTab: true, showInTabSavings: true, showAsKPIField: true, hierarchical: false, icon: 'mdi-palette-swatch', displayPrio: 4, customHandling: true, customHandlingIdentifier: 'MATERIAL_NAME', shareRouteLookup: 'material_id', isReferencedObject: true, pivotResultLookup: 'material' },
        cp: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, hierarchical: false, showAsKPIField: true, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        relevant_strategic: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-strategy', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        via_eprocurement: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        via_catalog: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        scope_poc: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        terms_of_payment: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        incoterm: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        direct_indirect: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        has_contract: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        has_skonto: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        skonto_taken: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes,  showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        purchasing_organization: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-home-group', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        organization: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        period_accrual: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        category_tag: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        delivery_condition: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        requester: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, requiredRoleSavings: 'MANAGEMENT', icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        responsible: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, requiredRoleSavings: 'MANAGEMENT', icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        contract: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        assignment: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        time_invoice_to_invoice_due_date: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        payment_on_time: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        delivery_on_time: { showInFilter: true, showInTransactionView: true, valueType: 'BOOLEAN' as IDataManagementFieldValueTypes, showAsKPIField: true, hierarchical: false, showInTab: true, showInTabSavings: true, icon: 'mdi-filter-variant', displayPrio: 7, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        placeholder_1: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        placeholder_2: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        placeholder_3: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        placeholder_4: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        placeholder_5: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        placeholder_6: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        placeholder_7: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        placeholder_8: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        placeholder_9: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: true, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        
        // fields for filter, not tabs
        category_id: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: false, showInTabSavings: false, isSavingsProjectField: true, hierarchical: true, icon: 'mdi-file-tree-outline', displayPrio: 1, customHandling: true, customHandlingIdentifier: 'CATEGORY', isReferencedObject: false, pivotResultLookup: undefined },
        vendor_id: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: true, transactionViewOrder: 9, showInTab: false, showInTabSavings: true, isSavingsProjectField: true, hierarchical: false, icon: 'mdi-domain', displayPrio: 2, customHandling: true, customHandlingIdentifier: 'VENDOR_NAME', shareRouteLookup: 'vendor_id', isReferencedObject: true, pivotResultLookup: 'vendor' },
        lang: { showInFilter: true, showInTransactionView: false, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, hierarchical: false, showInTab: false, icon: 'mdi-flag-variant-outline', displayPrio: 5, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        order_reference: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, hierarchical: false, showInTab: false, icon: 'mdi-filter-variant', displayPrio: 5, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        order_identifier: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, transactionViewOrder: 2, hierarchical: false, showInTab: false, icon: 'mdi-filter-variant', displayPrio: 5, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        invoice_identifier: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, transactionViewOrder: 4, hierarchical: false, showInTab: false, icon: 'mdi-filter-variant', displayPrio: 5, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        weight: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: false, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        tariff_number: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: false, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        gtin: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: false, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        cas_number: { showInFilter: true, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, showAsKPIField: false, showInTab: false, showInTabSavings: false, hierarchical: false, icon: 'mdi-filter-variant', displayPrio: 4, customHandling: false, customHandlingIdentifier: '', shareRouteLookup: '', isReferencedObject: false, pivotResultLookup: undefined },
        
        // other fields (for transaction view)
        type: { showInFilter: false, showInTransactionView: false, valueType: 'TEXT' as IDataManagementFieldValueTypes },
        spend_per_quantity: { showInFilter: false, showInTransactionView: true, valueType: 'CURRENCY_VALUE' as IDataManagementFieldValueTypes },
        order_position: { showInFilter: false, showInTransactionView: true, valueType: 'NUMBER' as IDataManagementFieldValueTypes, transactionViewOrder: 3 },
        spend: { showInFilter: false, showInTransactionView: true, valueType: 'CURRENCY_VALUE' as IDataManagementFieldValueTypes, transactionViewOrder: 6 },
        timestamp: { showInFilter: false, showInTransactionView: true, valueType: 'TIMESTAMP' as IDataManagementFieldValueTypes, transactionViewOrder: 5 },
        tax_rate: { showInFilter: false, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes },
        currency: { showInFilter: false, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes },
        quantity: { showInFilter: false, showInTransactionView: true, valueType: 'NUMBER' as IDataManagementFieldValueTypes, transactionViewOrder: 7 },
        quantity_supplied: { showInFilter: false, showInTransactionView: true, valueType: 'NUMBER' as IDataManagementFieldValueTypes },
        quantity_invoiced: { showInFilter: false, showInTransactionView: true, valueType: 'NUMBER' as IDataManagementFieldValueTypes },
        quantity_unit: { showInFilter: false, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, transactionViewOrder: 8 },
        skonto_rate: { showInFilter: false, showInTransactionView: true, valueType: 'NUMBER' as IDataManagementFieldValueTypes },
        timestamp_payment: { showInFilter: false, showInTransactionView: true, valueType: 'TIMESTAMP' as IDataManagementFieldValueTypes },
        timestamp_posting: { showInFilter: false, showInTransactionView: true, valueType: 'TIMESTAMP' as IDataManagementFieldValueTypes },
        timestamp_delivery: { showInFilter: false, showInTransactionView: true, valueType: 'TIMESTAMP' as IDataManagementFieldValueTypes },
        timestamp_running_time_start: { showInFilter: false, showInTransactionView: true, valueType: 'TIMESTAMP' as IDataManagementFieldValueTypes },
        timestamp_running_time_end: { showInFilter: false, showInTransactionView: true, valueType: 'TIMESTAMP' as IDataManagementFieldValueTypes },
        timestamp_invoice_due_date: { showInFilter: false, showInTransactionView: true, valueType: 'TIMESTAMP' as IDataManagementFieldValueTypes },
        skonto_amount: { showInFilter: false, showInTransactionView: true, valueType: 'NUMBER' as IDataManagementFieldValueTypes },
        description_short: { showInFilter: false, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, transactionViewOrder: 10 },
        description_long: { showInFilter: false, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes, transactionViewOrder: 11 },
        identifier: { showInFilter: false, showInTransactionView: true, valueType: 'TEXT' as IDataManagementFieldValueTypes },
        order_code: { showInFilter: false, showInTransactionView: false, valueType: 'TEXT' as IDataManagementFieldValueTypes, transactionViewOrder: 1 },

        // savings fields
        //timestamp_contract_start_date: { showInFilter: true, showInTransactionView: false, valueType: 'TEXT' as IDataManagementFieldValueTypes, moduleSpecific: 'SAVINGS' as IDataManagementFieldModuleSpecific, showAsKPIField: false, showInTab: false, showInTabSavings: false, hierarchical: false, icon: 'mdi-office-building-marker', displayPrio: 3, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        non_financial_benefits: { showInFilter: true, showInTransactionView: false, valueType: 'TEXT' as IDataManagementFieldValueTypes, moduleSpecific: 'SAVINGS' as IDataManagementFieldModuleSpecific, showAsKPIField: false, showInTab: false, showInTabSavings: true, hierarchical: false, icon: 'mdi-office-building-marker', displayPrio: 998, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
        savings_missed_reason: { showInFilter: true, showInTransactionView: false, valueType: 'TEXT' as IDataManagementFieldValueTypes, moduleSpecific: 'SAVINGS' as IDataManagementFieldModuleSpecific, showAsKPIField: false, showInTab: false, showInTabSavings: true, hierarchical: false, icon: 'mdi-office-building-marker', displayPrio: 999, customHandling: true, customHandlingIdentifier: 'SAVINGS_MISSED', isReferencedObject: false, pivotResultLookup: undefined },
        name: { showInFilter: true, showInTransactionView: false, valueType: 'TEXT' as IDataManagementFieldValueTypes, moduleSpecific: 'SAVINGS' as IDataManagementFieldModuleSpecific, showAsKPIField: false, showInTab: false, showInTabSavings: false, hierarchical: false, icon: 'mdi-office-building-marker', displayPrio: 999, customHandling: false, customHandlingIdentifier: '', isReferencedObject: false, pivotResultLookup: undefined },
    }
}
