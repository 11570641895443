export default {
    // general translations
    'common': {
        'add': 'Hinzufügen',
        'cancel': 'Stornieren',
        'description': 'Beschreibung',
        'delete': 'Löschen',
        'title': 'Titel',
        'save': 'sparen',
        'faq': 'FAQ',
        'contact': 'Kontaktiere uns',
        'tos': 'Nutzungsbedingungen',
        'policy': 'Datenschutz-Bestimmungen',
        'welcome': 'Willkommen',
        'goodMorning': 'Guten Morgen',
        'goodAfternoon': 'Guten Nachmittag',
        'goodEvening': 'Guten Abend',
        'on': 'an',
        'off': 'aus',
        'yes': 'Ja',
        'no': 'Nein',
        'chooseColumns': 'Spalten auswählen',
        'with': 'mit',
    },
    'usermenu': {
        'profile': 'Profil',
        'signin': 'Anmelden',
        'dashboard': 'Instrumententafel',
        'signout': 'Ausloggen'
    },
    'error': {
        'notfound': 'Seite nicht gefunden',
        'other': 'Ein Fehler ist aufgetreten'
    },
    'check': {
        'title': 'Neues Passwort festlegen',
        'backtosign': 'Zurück zur Anmeldung',
        'newpassword': 'Neues Passwort',
        'button': 'Legen Sie ein neues Passwort fest und melden Sie sich an',
        'error': 'Der Aktionslink ist ungültig',
        'verifylink': 'Link überprüfen ...',
        'verifyemail': 'E-Mail-Adresse überprüfen ...',
        'emailverified': 'Email überprüft! Umleiten ...'
    },
    'forgot': {
        'title': 'Passwort vergessen?',
        'subtitle': 'Geben Sie die E-Mail-Adresse Ihres Kontos ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.',
        'email': 'Email',
        'button': 'Fordere Passwort-Reset an',
        'backtosign': 'Zurück zur Anmeldung'
    },
    'login': {
        'title': 'Anmelden',
        'email': 'Email',
        'password': 'Passwort',
        'button': 'Anmelden',
        'orsign': 'Oder melden Sie sich mit an',
        'forgot': 'Passwort vergessen?',
        'noaccount': 'Sie haben noch keinen Account?',
        'create': 'Erstellen Sie hier eine',
        'error': 'Die E-Mail / Passwort-Kombination ist ungültig',
        'sso': 'Login mit externen Diensten',
        'passwordRequired': 'Passwort ist erforderlich',
        'emailRequired': 'E-Mail ist erforderlich',
        'emailInvalid': 'E-Mail ist ungültig'
    },
    'button': {
        'goToTool': 'Zum Tool',
        'yes': 'Ja',
        'cancel': 'Abbrechen',
        'submit': 'Übernehmen',
        'detailView': 'Detailansicht',
        'goToTransactions': 'Zu einzelnen Transaktionen springen',
        'goToCategoryLab': 'Mit aktueller Filterung ins CategoryLab springen',
        'goToCategoryLabWarning': 'Für den Sprung ins CategoryLab bitte zunächst die CategoryLab-Warengruppen aktivieren',
        'goToDownload': 'Daten herunterladen',
        'search': 'Suchen',
    },
    'dataTable': {
        'limitWarning1': 'Nur die ersten ',
        'limitWarning2': ' Elemente sind aufgeführt.',
        'limitWarningForTableSort': 'Die Sortierung der Tabelle bezieht sich ausschließlich auf diese Elemente.',
    },
    form: {
        validation: {
            requiredValue: 'Muss gesetzt sein',
            onlyNumbers: 'Darf nur aus Zahlen bestehen',
            year: 'Jahr muss aus 4 Zahlen bestehen',
        },
    },
    // Vuetify components translations
    '$vuetify': {
        'badge': 'Abzeichen',
        'close': 'Schließen',
        'dataIterator': {
            'noResultsText': 'Keine übereinstimmenden Aufzeichnungen gefunden',
            'loadingText': 'Artikel laden ...'
        },
        'dataTable': {
            'itemsPerPageText': 'Zeilen pro Seite:',
            'ariaLabel': {
                'sortDescending': 'Absteigend sortiert.',
                'sortAscending': 'Aufsteigend sortiert.',
                'sortNone': 'Nicht sortiert.',
                'activateNone': 'Aktivieren Sie diese Option, um die Sortierung zu entfernen.',
                'activateDescending': 'Aktivieren Sie diese Option, um absteigend zu sortieren.',
                'activateAscending': 'Aktivieren Sie diese Option, um aufsteigend zu sortieren.'
            },
            'sortBy': 'Sortieren nach'
        },
        'dataFooter': {
            'itemsPerPageText': 'Objekte pro Seite:',
            'itemsPerPageAll': 'Alle',
            'nextPage': 'Nächste Seite',
            'prevPage': 'Vorherige Seite',
            'firstPage': 'Erste Seite',
            'lastPage': 'Letzte Seite',
            'pageText': '{0} - {1} von {2}'
        },
        'datePicker': {
            'itemsSelected': '{0} ausgewählt',
            'nextMonthAriaLabel': 'Nächsten Monat',
            'nextYearAriaLabel': 'Nächstes Jahr',
            'prevMonthAriaLabel': 'Vorheriger Monat',
            'prevYearAriaLabel': 'Vorheriges Jahr'
        },
        'noDataText': 'Keine Daten verfügbar',
        'carousel': {
            'prev': 'Vorheriges Bild',
            'next': 'Nächstes Bild',
            'ariaLabel': {
                'delimiter': 'Karussell Folie {0} von {1}'
            }
        },
        'calendar': {
            'moreEvents': '{0} mehr'
        },
        'fileInput': {
            'counter': '{0} Dateien',
            'counterSize': '{0} Dateien (insgesamt {1})'
        },
        'timePicker': {
            'am': 'AM',
            'pm': 'PM'
        },
        'pagination': {
            'goto': 'Gehe zu Seite',
            'ariaLabel': {
                'wrapper': 'Paginierungsnavigation',
                'next': 'Nächste Seite',
                'previous': 'Vorherige Seite',
                'page': 'Gehe zu Seite {0}',
                'currentPage': 'Aktuelle Seite, Seite {0}'
            }
        }
    },
    // kiresult translations
    navigation: {
        headlineDashboard: 'Übersicht & Daten',
        headlineDataQuality: 'Datenqualität',
        headlineAnalytics: 'Datenanalyse',
        headlineMeta: 'Übergreifend',
        headlineSuperSettings: 'Super-User',
        dashboard: 'Dashboard',
        dataManagement: 'Datenmanagement',
        overview: 'Überblick',
        upload: 'Upload',
        categorylab: 'CategoryLab',
        rules: 'Regeln',
        model: 'Warengruppenvorhersage',
        spendAnalytics: 'Spend Analytics',
        spendAnalyticsDashboard: 'Spend Analysis Dashboard',
        categoryDetails: 'Warengruppen-Details',
        cluster: 'Harmonisierung',
        vendorDetails: 'Lieferanten-Details',
        pivot: 'Pivot-Tabelle',
        transactions: 'Transaktionen',
        kpi: 'KPI',
        kpiDashboard: 'KPI-Dashboard',
        unitPrices: 'Einzelpreis-Tracking',
        reportsDashboard: 'Reports & Download',
        savings: 'Savings',
        spendInsights: 'Spend Insights',
        savingsActions: 'Savings & Actions',
        savingsDashboard: 'Savings Dashboard',
        savingsProjects: 'Projekterfassung',
        documentation: 'Dokumentation & FAQ',
        profile: 'Benutzerprofil',
        settings: 'Einstellungen',
        etl: "API",
        userManagement: 'Benutzerverwaltung',
        facts: 'Unternehmens-Informationen',
        globalOrganizations: 'Globale Organisationen',
        settingsOfOrganization: 'Einstellungen der Organisation',
        categorylabSchema: 'CategoryLab Schema',
        salesCaseSettings: 'Sales Case Einstellungen',
        lutOverview: "Dimensionstabellen",
        toolbar: {
            docGlossary: 'Glossar',
            docGlossaryNotAvailable: 'Das Glossar für dein Unternehmen ist nicht verfügbar. Wende dich an das kiresult-Team zur Erstellung eines Glossars!',
            docCheatSheet: 'CheatSheet',
            docAssistant: 'Assistent',
            docAssistantBeta: '(Beta-Test) Diese Funktionalität ist noch nicht verfügbar.',
        },
        views: {
            labelViewMenu: 'Gespeicherte Sichten',
            noViewsAvailable: 'Noch keine Sichten erstellt. Sei der erste!',
            editViews: 'Sichten verwalten',
            showViews: 'Sichten anzeigen',
            addViews: 'Sicht hinzufügen',
            applyView: 'Sicht übernehmen',
            deleteConfirmMessage: 'Bist du dir sicher, dass du diese Sicht löschen möchtest?',
            ownView: 'Eigene Sicht',
            globalView: 'Globale Sicht',
            saveCurrentSettingsAsView: 'Aktuelle Filtereinstellungen als Sicht speichern',
            nameForNewView: 'Name für die neue Sicht festlegen',
            descriptionForNewView: 'Beschreibung für die Sicht festlegen',
            name: 'Name',
            description: 'Beschreibung',
            typeOfView: 'Art der Sicht',
            actions: 'Aktionen',
            availabilityOfView: 'Verfügbarkeit dieser Sicht',
            onlySaveForMyself: 'Sicht nur für mich selbst speichern',
            saveForAllUsers: 'Sicht für alle User speichern',
            saveAsRoute: 'Nur Filter oder auch Seite als Verlinkung speichern?',
            saveFiltering: 'Aktuelle Filterung speichern',
            saveFilteringAndSite: 'Aktuelle Filterung und Seite speichern',
            routeYes: 'Ja',
            routeNo: 'Nein',
            saveFilterSettings: 'Filtereinstellung speichern',
            savedViewMessage: 'Sicht angelegt',
            viewAlreadyExistsMessage: 'Es existiert schon eine Sicht mit dieser Bezeichnung. Die Sicht wurde nicht gespeichert.',
            pleaseProvideViewName: 'Bitte gib einen Namen für die Sicht an.',
            emptyView: 'Erstelle deine erste Sicht über die Toolbar oben.',
            noDescription: 'Keine Beschreibung vorhanden',
            details: {
                details: 'Details',
                filter: 'Filter',
                time: 'Zeitraum',
                link: 'Link zur Seite',
                description: 'Beschreibung',
                allExcept: 'Alles außer',
            },
            links: {
                researchDashboard: 'Spend Analytics Dashboard',
                researchCategoryDetail: 'Warengruppen-Details',
                researchVendorDetail: 'Kreditoren-Details',
                researchPivot: 'Pivot-Tabelle',
                researchTransactions: 'Transaktionen',
            },
        },
    },
    docs: {
        docToVideo: 'Schaue dir ein Video zu diesem Thema an.',
        cheatSheet: {
            filter: {
                goToTransactionCategoryLabDownloadTitle: 'Transaktionen, CategoryLab, Download',
                goToTransactionCategoryLabDownloadMsg: 'In allen Aktionen werden die gesetzten Filter im Filter-Menü übernommen und angewendet. "Transaktionen": Springe zu den einzelnen Transaktionen hinter den Visualisierungen. - "CategoryLab": Springe direkt in das CategoryLab und kategorisiere deine Transaktionen. - "Download": Ein Standardexport wird dir per E-Mail zugeschickt.',
                activeFilterTitle: 'Aktive Filter',
                activeFilterMsg: 'Die Anzahl der gerade aktiven Filter wird angezeigt. Alle Filter können über den Button "Alle Filter entfernen" (ganz rechts) zurückgesetzt werden.',
                filterFieldTitle: 'Filter- und Suchfeld',
                filterFieldMsg: 'Filter können einfach gesetzt werden. Dafür den Namen des zu filternden Attributs eingeben und in der Liste auswählen. Im Fall, dass der eingegebene Name nicht genau übereinstimmt werden die wahrscheinlichsten Treffer oben in der Liste angezeigt. Weitere Filter können durch Aufklappen des Filter-Menüs (ganz rechts) benutzt werden.',
                categoryTypeTitle: 'Warengruppentypen',
                categoryTypeMsg: 'Es stehen mehrere Typen vom Warengruppen für die Visualisierungen und Auswertungen zur Verfügung. "CategoryLab deaktiviert": Es werden nur die in den hochgeladenen Daten vorhandenen Warengruppen verwendet und es findet keine Anreicherung durch kiresult statt. - "CategoryLab aktiviert": Die hochgeladenen Warengruppen werden durch kiresult und durch die Arbeit im CategoryLab angereichert. - "Lieferanten-Basis" (optional): Anstatt auf Ebene der Transaktionen Warengruppen anzureichern, werden passende Warengruppen den Lieferanten zugeordnet.',
                harmonizationTitle: '',
                harmonizationMsg: 'Wenn die Harmonisierung angeschaltet wird, werden statt den Lieferanten die harmonisierten Lieferantengruppen angezeigt und ausgewertet. Lieferantengruppen werden durch den Zusatz "H" in der Kreditorennummer gekennzeichnet. Auch die Filter und die Suchmöglichkeiten beziehen sich bei Aktivierung auf die harmonisierten Lieferantengruppen. Falls in den Einstellungen neben Lieferanten noch andere Merkmale zur Harmonisierung festgelegt sind, gilt alles äquivalent auch für diese.',
                timeTitle: 'Zeitfilter',
                timeMsg: 'Der Zeitraum der Daten kann flexibel gefiltert werden. Weiter rechts kann ein Vergleichszeitraum eingestellt werden. Damit wird neben jedem Wert in der Toolbox ein Wachstumsfaktor angezeigt. Der Zeitraum kann auch durch einen Zoom in den Zeitgrafen eingestellt werden. Der Zeitfilter sollte immer im Auge behalten werden, sodass zu jeder Zeit klar ist, welche Daten gerade gefiltert sind.',
            },
            time: {
                timeChartTypesTitle: 'Zeiträume',
                timeChartTypesMsg: 'Für eine bessere Betrachtung von sehr große oder sehr kleinen Zeiträumen kann die Schrittbreite verändert werden. Damit können Trends besser erkannt werden.',
            },
            navigation: {
                expandToolbarTitle: 'Toolbar aufklappen',
                expandToolbarMsg: '',
            },
            vendor: {
                goToDetailViewTitle: 'Auswertung über alle Lieferanten',
                goToDetailViewMsg: 'Springe zu einer Auswertung über alle Lieferanten. Die im Filter-Menü gesetzten Filter werden übernommen und angewendet.',
                goToDetailTitle: 'Weiter Details',
                goToDetailMsg: 'Zeige Details zu einem einzelnen Lieferanten. Andere gesetzte Filter im Filter-Menü werden übernommen und angewendet.'
            },
            category: {
                goToDetailViewTitle: 'Auswertung über alle Warengruppen',
                goToDetailViewMsg: 'Springe zu einer Auswertung über alle Warengruppen. Die im Filter-Menü gesetzten Filter werden übernommen und angewendet.',
                goToDetailTitle: 'Weiter Details',
                goToDetailMsg: 'Zeige Details zu einer einzelnen Warengruppe. Andere gesetzte Filter im Filter-Menü werden übernommen und angewendet.',
                drilldownTitle: 'Drilldown',
                drilldownMsg: 'Klicke auf den Namen der Warengruppe, um in die Warengruppe und auf die nächste Hierarchie zu springen.',
                expandMoreInformationTitle: 'Mehr Informationen aufklappen',
                expandMoreInformationMsg: 'Klicke hier, um für dieses Element mehr Informationen aufklappen zu lassen. So kann sich schnell ein Überblick verschafft werden, ohne jedes Element im Filter-Menü seperate einzustellen.',
            },
            dashboard: {
                totalSpendTitle: '',
                totalSpendMsg: 'Gesamter Spend in der Toolbox. Für diesen Wert werden alle hochgeladenen Daten ohne Filter ausgewertet.',
                vendorCountTitle: '',
                vendorCountMsg: 'Anzahl der Lieferanten in der Toolbox. Für diesen Werte werden alle hochgeladenen Daten ohnen Filter ausgewertet.',
                spendInsightsCardTitle: 'Demo für Spend-Insights',
                spendInsightsCardMsg: 'Das Modul dient nur als Demo-Visualisierung. Alle Daten in der Demo sind simuliert und stehen in keinen Zusammenhang mit der restlichen Toolbox.',
            },
            fileOverview: {
                goToRefreshUploadTitle: 'Refresh, Upload',
                goToRefreshUploadMsg: '"Refresh": Aktualisiere den Status aller Dateien. - "Upload": Springe in das Menü zum hochladen neuer Daten.',
                deleteDownloadTitle: 'Löschen, Download',
                deleteDownloadMsg: '"Löschen": Lösche die Daten dieser Datei aus der Toolbox. - "Download": Lasse dir die Orginaldatei, die für den Upload genutzt wurde, per E-Mail zusenden.',
                statusTitle: 'Status der Datei',
                statusMsg: 'Der Status der Datei wird angezeigt. Sollte sich der Status nicht von alleine aktualisieren, kann eine manuelle Aktualisierung mit dem Button oben rechts vorgenommen werden. Folgender Status kann auftreten: "In Vorbereitung": Die Daten werden hochgeladen. Ein Ladebalken zeigt den Fortschritt an. - "Vorhersagen werden erstellt": Auf Basis der neuen Daten werden die Warengruppenvorhersagen und Harmonisierungen angepasst. - "Wird gelöscht": Die Daten werden aus der Toolbox entfernt. - "Bereit mit KI-Vorhersagen": Die Datei wurde erfolgreich hochgeladen. - "Abgebrochen": Ein Fehler ist während des Uploads aufgetreten. In diesem Fall hilft der Support support@kiresult.com gerne weiter.',
            },
            categoryLab: {
                dashboard: {
                    startTitle: 'Starte das CategoryLab',
                    startMsg: 'Starte das CategoryLab mit allen Filtern, die gerade im Filter-Menü gesetzt sind. Das Starten des CategoryLab dauert je nach Datenmenge ein paar Sekunden. Das CategoryLab zeigt dir eine Zusammenfassung der Transaktionen in der Toolbox an. Auf dieser Basis können Warengruppenzuordnungen vorgenommen und ergänzt werden.',
                    updateTitle: 'Aktualisiere alle Transaktionen',
                    updateMsg: 'Aktualisiere alle Transaktionen mit den letzten Änderungen im CategoryLab. Der Prozess kann je nach Datenmenge ein paar Minuten dauern. Während des Updates kann die Toolbox ohne Einschränkungen verwendet werden. Auch kann ohne Probleme der Logout genutzt oder das Browserfenster geschlossen werden.',
                    optionsTitle: 'Weitere Optionen für das CategoryLab',
                    optionsMsg: 'Wähle aus weiteren Filtern, die auf deine CategoryLab Session angewendet werden sollen. Beachte, dass die Filter, wenn du Änderungen vornimmst, den neuen Status erst anzeigen, nachdem die Transaktionen aktualisiert worden sind.',

                    performanceTitle: 'Visualisierung der Warengruppenzuordnung',
                    performanceMsg: 'Ausgewählte Filter werden auf alle Visualisierungen angewendet. Für Erklärungen zu den Visualisierungen mit dem Mauszeiger auf das Info-Symbol gehen.',
                },
                table: {
                    modelStatusTitle: 'Status',
                    modelStatusMsg: 'Der Status der KI wird angezeigt. Der Status muss immer auf "KI bereit" stehen.',
                    expandFilterTitle: 'Weitere Filter',
                    expandFilterMsg: '',
                    chartProbabilityTitle: 'Genauigkeiten',
                    chartProbabilityMsg: 'Die Genauigkeit der vorhergesagten Warengruppen wird angezeigt. Durch Klick auf eine Gruppe im Chart wird ein entsprechender Filter gesetzt.',
                    processedTransactionsTitle: '',
                    processedTransactionsMsg: 'Anzahl der hinter dieser CategoryLab-Session liegenden Transaktionen, die durch eine manuelle Regel klassifiziert werden.',
                    processedSpendTitle: '',
                    processedSpendMsg: 'Spend der in hinter dieser CategoryLab-Session liegenden Transaktionen. Falls mit Filtern in das CategoryLab gesprungen wurde, ist dieser Spend nicht gleich dem gesamten Spend in der Toolbox. Auch wenn ohne Filter in das CategoryLab gesprungen wurde, kann der Wert geringer sein, da für eine bessere Performance Tail-Spend nicht berücksichtigt wird. Tail-Spend kann explizit durch setzten entsprechender Filter vor dem Start des CategoryLab adressiert werden.',
                    chartCategorySharesTitle: '',
                    chartCategorySharesMsg: 'Die angezeigte Warengruppenstruktur spiegelt ausschließlich die jetzige CategoryLab-Session wieder, und kann sich von der Warengruppenstruktur in der gesamten Toolbox unterscheiden. Durch Klick auf eine Warengruppe wird ein entsprechender Filter gesetzt.',
                    filterColumnContentTitle: '',
                    filterColumnContentMsg: 'Transaktionen, die einen ähnlichen Begriff beinhalten, werden selektiert und nach Übereinstimmung sortiert angezeigt. Alle im CategoryLab angezeigten Merkmale werden durchsucht. Eine Ausnahme ist die Warengruppe, für die ein eigenes Filterfeld existiert.',
                    filterChosenCategoryTitle: '',
                    filterChosenCategoryMsg: 'Zum Auswählen einer Warengruppe kann die Warengruppennummer angegeben werden. Eine Suche über den Namen ist nicht möglich. Für Warengruppen der obersten Hierarchie kann auch in dem Chart der Warengruppenstruktur (oben rechts) das Element ausgewählt werden.',
                    filterLanguageTitle: '',
                    filterLanguageMsg: 'Bei dem Sprung in das CategoryLab wird jeder Transaktion eine Sprache zugeordnet. Für jede Sprache wird ein, dafür trainierte, KI angesprochen.',
                },
                categoryToolbar: {
                    optionsTitle: 'Weiter Optionen',
                    optionsMsg: 'Weitere Optionen für die Warengruppensuche ein- und aufklappen',
                    onlyAITitle: '',
                    onlyAIMsg: 'Zeige nur die Warengruppen an, die von der KI als wahrscheinlich eingestuft werden.',
                },
            },
            rules: {
                searchFieldTitle: 'Suche nach einer Regel',
                searchFieldMsg: 'Suche nach einer bestimmten Regel. Die Suche kann über die anderen Filter eingeschränkt werden. Im Fall das der eingegebene Name nicht genau übereinstimmt, werden die wahrscheinlichsten Treffer oben in der Liste angezeigt.',
                ruleTypeFieldTitle: 'Wähle den Typ der Regel',
                ruleTypeFieldMsg: 'Es existieren verschiedene Arten von Regeln. "Manuell": Regeln, die direkt in der Regelliste angelegte werden. - "CategoryLab": Regeln, die über das CategoryLab angelegt werden. - "Kreditoren-DB": Regeln aus der kiresult-Datenbank für Kreditoren. Diese Regeln können nicht gelöscht oder angepasst werden.',
            },
            cluster: {
                typeFieldTitle: 'Wähle den Typ der Cluster',
                typeFieldMsg: 'Je nach Einstellung wird nicht nur der Kreditor harmonisiert, sondern auch andere Merkmale. In diesem Fall können über das Dropdown die verschiedenen Merkmale ausgewählt werden.',
                clusterContentTitle: 'Inhalt des Clusters',
                clusterContentMsg: '',
                clusterTitle: 'Name des Clusters',
                clusterMsg: '',
            },
            tabs: {
                headerTitle: '',
                headerMsg: 'Weitere Visualisierungen werden in den Tabs angezeigt. Die im Filter-Menü gesetzten Filter werden angewendet.',
            },
            transactions: {
                searchTitle: 'Suche',
                searchMsg: 'Mithilfe der Freitextsuche kann der gesamte Datenbestand durchsucht werden. Dafür einfach mehrere Stichwörter durch Leerzeichen getrennt eingeben und bestätigen. Die dann angezeigten Transaktionen sind nach Übereinstimmung mit den Stichwörtern sortiert. Die Freitextsuche kann beliebig mit den Filtern im Filter-Menü kombiniert werden. Merkmale, die nicht durch die Freitextsuche abgedeckt werden, können zuerst im Filter-Menü isoliert gesucht und eingestellt werden.',
                selectTitle: 'Spaltenkonfiguration',
                selectMsg: 'Wähle nur die Spalten aus, die gerade interessant sind. Dadurch werden die Information kompakter auf dem Bildschirm dargestellt.'
            },
            pivot: {
                tableTitle: 'Pivot-Tabelle',
                tableMsg: 'Ziehe die Merkmale in die Spalten oder die Zeilen, um eine Pivot-Tabelle zu erzeugen. Die Reihenfolge der Merkmale in der Zeile oder Spalte bestimmt den Aufbau der Pivot-Tabelle und kann zu jeder Zeit angepasst werden. Die Pivot-Tabelle kann mit dem Filter-Menü kombiniert werden, um den Datenbereich schon im Vorfeld einzuschränken. Dies erlaubt einen besseren Überblick bei Merkmalen mit vielen verschiedenen Attributen.',
            },
        },
    },
    lut: {
        statusMsg: 'Dimensionstabellen werden angewendet',
        successMsg: 'Dimensionstabellen wurden erfolgreich angewendet',
        title: 'Liste der Dimensionstabellen',
        searchFieldTitle: 'Suche',
        delete: {
            title: 'Dimensionstabelle löschen',
            msg: 'Bist du sicher, dass du diese Dimensionstabelle löschen möchtest?',
            msgConfirm: 'Bist du dir wirklich sicher?',
            msgAskForLUTName: 'Diese Änderung kann nicht rückgängig gemacht werden. Bitte gib den Namen der Dimensionstabelle ein und klicke noch einmal auf "Ja".',
            insertLUTName: 'Name der Dimensionstabelle',
        },
        create: {
            createTitle: 'Dimensionstabelle erstellen',
            nameFieldTitle: 'Name der Dimensionstabelle',
            nameFieldPlaceholder: 'Name',
            nameConflictError: 'Es existiert bereits eine Dimensionstabelle mit diesem Namen.',
            keyFieldsInputTitle: 'Key-Felder für die LUT festlegen',
            keyFieldsInputPlaceholder: 'Key-Feld Name',
            valueFieldInputPlaceholder: 'Ziel-Feld Name',
            createButtonSubmit: 'Dimensionstabelle erstellen',
            addKeyFieldButton: 'Key-Feld hinzufügen',
            fileUploadPlaceholder: 'Datei auswählen',
            fileUploadMapping: 'Spalte für '
        },
        update: {
            priority: 'Ändere die Reihenfolge, in der die Tabellen angewendet werden. Die unteren Tabellen werden zuerst angewendet und die oberen zuletzt. Bei gleicher Priorität kann die Reihenfolge zufällig sein.',
        },
        preview: {
            title: ' - Zeilen',
            details: 'Details',
            status: {
                OUTDATED: 'OUTDATED',
                OUTDATED_details: 'Die Werte dieser Tabelle wurden verändert. Aktualisiere alle Tabellen, um die Änderungen anzuwenden.',
                UPLOADING: 'UPLOADING',
                PENDING: 'PENDING',
                PROCESSING: 'PROCESSING',
                DELETING: 'DELETING',
                DEACTIVATED: 'DEACTIVATED',
                DATA_LOSS: 'DATA LOSS',
                DATA_LOSS_details: 'Diese Tabelle kann andere Daten überschreiben und wurde deaktiviert. Wende dich an den Support support@kiresult.com für Hilfe.',
            },
            target_type: {
                TRANSACTIONS: 'Transaktionen',
                SAVINGS_PROJECTS: 'Savings-Projekte',
                ALL: 'Alle',
            },
            header: {
                name: 'Name',
                key_fields: 'Key-Felder',
                value_field: 'Ziel-Feld',
                actions: 'Aktionen',
                user: 'User',
                created_at: 'erstellt am',
                created_by: 'erstellt von',
                updated_at: 'aktualisiert am',
                priority: 'Priorität',
            },
            download: 'Download',
            createRowButton: 'Zeile erstellen',
            createRowTitle: 'Zeile erstellen',
            createRowButtonSubmit: 'Zeile erstellen',
            keyFieldTitle: 'Alle Key-Felder einfügen',
            valueFieldTitle: 'Ziel-Feld einfügen',
            updateRowTitle: 'Zeile bearbeiten',
            updateRowButtonSubmit: 'Zeile aktualisieren',
            searchFieldTitle: 'Suche nach einer Zeile',
        }
    },
    file: {
        title: 'Alle Dateien',
        download: 'Datei herunterladen',
        delete: {
            title: 'Datei löschen',
            msg: 'Bist du sicher, dass du diese Datei löschen möchtest?',
            msgConfirm: 'Bist du dir ganz sicher?',
            msgAskForFilename: 'Diese Änderung kann nicht rückgängig gemacht werden. Bitte gib den Dateinamen ein und klicke noch einmal auf "Ja".',
            filename: 'Name der Datei:',
        },
        upload: {
            title: 'Dateiupload',
            file: 'Ausgewählte Datei',
            attributes: 'Verfügbare Merkmale',
            selectedAttributes: 'Ausgewählte Merkmale',
            selectedAttributesPlaceholder: 'Merkmal zuordnen...',
            dropAttributes: 'Merkmale hierher ziehen',
            window: 'Datei hineinziehen oder Klicken zum Hochladen',
            submit: 'Abschließen',
            preview: 'Vorschau der Datenstruktur',
            previewColumn: 'ausgewählte Spalte',
            error: 'Leider gab es einen Fehler beim Upload, versuche es bitte erneut.',
            success: 'Upload erfolgreich',
            back: 'Zurück zur Übersicht',
            validation: {
                pending: 'Die ausgewählten Merkmale werden validiert. Bitte einen Moment Geduld.',
                error: 'Die ausgewählten Merkmale haben weisen noch Fehler auf. Bitte prüfe die Zuordnung.',
            },
            status: {
                pending: {
                    header: 'Pending',
                    description: 'Die Datei wird gerade von einem User angelegt.',
                },
                initializing: {
                    header: 'In Vorbereitung',
                    description: 'Die Datei wird durch kiresult vorprozessiert, es sind keine Aktionen möglich. In der Zwichenzeit kann keine weitere Datei hochgeladen oder gelöscht werden.',
                },
                predicting: {
                    header: 'Vorhersagen werden erstellt',
                    description: 'Das KI-Modell bearbeitet die Daten und erstellt Vorhersagen für Warengruppen. In der Zwichenzeit kann keine weitere Datei hochgeladen oder gelöscht werden.',
                },
                ready: {
                    header: 'Bereit mit KI-Vorhersagen',
                    description: 'Die Datei ist vollständig vorbereitet, inklusive KI-Vorhersagen.',
                },
                deleting: {
                    header: 'Wird gelöscht',
                    description: 'Die Datei wird gerade gelöscht. In der Zwichenzeit kann keine weitere Datei hochgeladen oder gelöscht werden.',
                },
                failed: {
                    header: 'Abgebrochen',
                    description: 'Während des Uploads ist ein Fehler aufgetreten.',
                },
            },
        },
    },
    facts: {
        types: {
            EMPLOYEE: 'Mitarbeiter im Einkauf',
            BUDGET: 'Budget',
            REVENUE: 'Umsatz',
            PRICE_TARGET: 'Zielpreis',
        },
        information: 'Information',
        subvalue: 'Dimension',
        subtype: 'Dimension Attribut',
        action: 'Aktion',
        title: 'Unternehmens-Informationen',
        deleteConfirmation: 'Bist du dir sicher, dass du diese Information löschen möchtest?',
        edit: 'Information bearbeiten',
        update: 'Aktualisieren',
        create: 'Information hinzufügen',
        type: 'Art der Information',
        dimensionAttribute: 'Dimension Attribut',
        value: 'Wert',
        year: 'Jahr',
        add: 'Hinzufügen',
        loading: 'Lädt...',
        noResults: 'Keine Ergebnisse gefunden',
        dimension: 'Dimension',
        sucessInformationCreated: 'Information angelegt',
        sucessInformationEdited: 'Information bearbeitet',
    },
    pivot: {
        attributes: 'Verfügbare Merkmale',
        rows: 'Zeilen',
        cols: 'Spalten',
        hideSettings: 'Einstellungen verstecken',
        showSettings: 'Einstellungen zeigen',
        noData: 'Bitte Merkmale entweder in die Spalten oder Zeilen ziehen',
        timeFrameYears: 'Zeitraum: Jahre',
        timeFrameMonths: 'Zeitraum: Monate',
        timeFrameWeeks: 'Zeitraum: Wochen',
        categoryLevel: 'Warengruppenlevel',
    },
    download: {
        success: 'Die exportierte Datei wird Ihnen per E-Mail zugesendet.',
    },
    settings: {
        save: "Speichern",
        organization: {
            header: "Organisation",
            name: "Name",
            modules: "Module",
            timerange: "Zeitraum",
            userquota: "Benutzer-Quota",
            transactionquota: "Transaktions-Quota",
            apiquota: "API-Quota",
        },
        language: {
            header: "Spracheinstellungen bearbeiten (für Transaktionen)",
            label: "Gesetzte Sprachen",
            success: "Sprachen erfolgreich gesetzt",
        },
        cluster: {
            header: "Harmonisierung bearbeiten",
            label: "Gesetzte Cluster",
            success: "Cluster erfolgreich gesetzt",
        },
        download: {
            header: "Download bearbeiten",
            label: "Gesetzte Spalten",
            success: "Spalten erfolgreich gesetzt",
        },
        aggregation: {
            header: "Warengruppenaggregation bearbeiten",
            label: "Gesetzte Attribute",
            success: "Attribute erfolgreich gesetzt",
        },
        fileTransfer: {
            header: "Sicherer Upload auf den kiresult-Server",
        },
        etl: {
            title: "Einstellung der API",
            SAVINGS_PROJECTS: "Savings",
            time_status: "Zuletzt aktualisiert am",
            api_auth: "Authentifizierung",
            api: "Daten",
            success: "API Einstellungen gespeichert",
        },
        form_rules: {
            required: "Pflichtfeld",
            no_comma: "Kommata könnten zu Fehler führen",
        },
    },
    profile: {
        headline: "Benutzerprofil",
        name: "Name",
        email: "E-Mail",
        edit: "Bearbeiten",
        changePassword: "Passwort ändern",
        editUserProfile: "Benutzerprofil bearbeiten",
        cancel: "Abbrechen",
        reset: "Reset",
        save: "Speichern",
    },
    changePassword: {
        headline: "Passwort ändern",
        user: "Benutzer",
        password: "Passwort",
        passwordConfirm: "Passwort bestätigen",
        passwordHasToBeSet: "Passwort muss ausgefüllt sein",
        passwordMustContainFigure: "Passwort muss mindestens eine Zahl enthalten",
        passwordLengthError: "Das Passwort muss mehr als 12 Zeichen haben",
        passwordMustBeIdentical: "Passwort muss identisch zu vorherigem sein",
    },
    cluster: {
        collectionFilterLabel: "Art der Cluster",
        headline: "Harmonisierung - Cluster",
        header: {
            cluster: "Cluster",
            objects: "Objekte",
        },
    },
    dashboard: {
        categoryLab: {
            title: 'Datenqualität',
            subtitle: 'Detaillierte Zuordnung deiner Spend-Daten zu Warengruppen durch unsere KI.',
            linkCategoryLab: 'Zum CategoryLab',
            linkCategoryLabRules: 'Regeln für das CategoryLab',
            numberRules: '# Regeln',
            shareCategorizerSpend: 'Kategorisierter Spend',
        },
        cluster: {
            linkCluster: 'Harmonisierung deiner Lieferanten',
        },
        analtyics: {
            subtitle: 'Visualisierung und Detailanalyse deiner Daten.',
            totalSpend: 'Gesamter Spend',
            numberVendors: '# Lieferanten',
            linkDashboard: 'Dashboard',
            linkCategoryDetails: 'Detailanalyse Warengruppen',
            linkVendorDetails: 'Detailanalyse Lieferanten',
            linkPivot: 'Pivot-Tabelle',
        },
        insights: {
            subtitle: 'Automatisierte Dateninterpretation und Maßnahmenableitung.',
            caseSubtitle: 'Was heißt das für mein Unternehmen?',
            ebit: 'EBIT im Jahr 2019',
            ebitPotential: 'Potenzial für EBIT-Steigerung von 44%',
        },
        documentation: {
            title: 'Dokumentation & FAQ',
            subtitle: 'Video-Touren durch deine kiresult-Plattform. Lerne alle Funktionalitäten im Detail kennen.',
            link: 'Zur Dokumentation',
            linkOverviewVideo: 'Übersicht über deine kiresult Toolbox',
            linkGlossary: 'Glossar',
        },
        views: {
            title: 'Gespeicherte Sichten',
            subtitle: 'Wähle aus deinen letzten gespeicherten Sichten.',
            link: 'Verwalte deine Sichten',
            countLocal: 'Eigene Sichten',
            countGlobal: 'Globale Sichten',
        },
        kpi: {
            title: 'Einkaufscontrolling',
            subtitle: 'Übersicht über die Performance deiner Einkaufsorganisation.',
            linkKPIDashboard: 'Zum KPI-Dashboard',
            linkFacts: 'Deine Unternehmens-Informationen',
            countSuccess: 'Ziel erreicht',
            countBetween: 'Nahe Ziel',
            countFailed: 'Ziel verfehlt',
        },
        module: {
            mostImportantKPI: 'Deine wichtigsten Kennzahlen',
            toKPIDashboard: 'zum KPI-Dashboard',
            top5SpendDistribution: 'TOP5 Spend-Verteilung',
            toSpendAnalyticsTool: 'zum Spend-Analytics-Tool',
            savedViews: 'Gespeicherte Sichten',
            fastLinks: 'Schnellzugriff',
        },
    },
    categoryLab: {
        rule: {
            rule: 'Regel',
            header: 'CategoryLab - Regeln',
            schemaFilterLabel: 'Merkmale',
            searchFilterLabel: 'Nach Regeln suchen',
            typeFilterLabel: 'Typ der Regel',
            add: 'Hinzufügen',
            table: {
                header: {
                    rule: 'Regel',
                    feature: 'Merkmal',
                    category: 'Warengruppe',
                    created_at: 'Erstellt am',
                    created_by: 'Erstellt von',
                    actions: 'Aktionen',
                },
            },
            ki: 'Diese Regel wurde automatisch erstellt und kann nicht verändert werden.',
        },
        model: {
            title: 'Warengruppen-Klassifizierung (Freitext)',
            formLabel: 'Text eingeben',
            formPlaceholder: 'Gib einen Text ein, um eine Warengruppen-Klassifizierung zu erhalten.',
            formButton: 'Klassifizieren',
            formResult: 'Warengruppe',
        },
        loadingStatus: {
            step1: 'Hole die relevanten Daten aus deiner Datenbank...',
            step2: 'Fasse die Daten zusammen, damit du dir weniger anschauen musst...',
            step3: 'Wende deine individuelle KI auf die Daten an...',
            step4: 'Berechne Dashboards für den einfachen Überblick. Bald fertig...',
        },
        dashboard: {
            subtitle: 'Lege die Kriterien fest, mit denen du in das CategoryLab springen möchtest.',
            applyChanges: 'Änderungen des CategoryLabs übernehmen',
            timeFilterText: 'Zeitliche Eingrenzung',
            dataExtracts: 'Datenextrakte',
            performance: 'Performance der Klassifizierung',
            comparison: 'Vergleich der Klassifizierungsarten',
            deviationDetails: 'Abweichende Warengruppen im Detail',
            depth: 'Tiefe der Zuordnung',
            stats: 'Statistiken',
            share: 'Anteil mit Kategorisierung',
            consistency: 'Übereinstimmung der Zuordnungen',
            advanced: 'Weitere Sichten',
            start: 'Starte das CategoryLab',
            options: 'Konfigurierung des CategoryLab. Beachte, dass die Filter bei Änderungen im CategoryLab den neuen Status erst anzeigen, nachdem die Transaktionen aktualisiert worden sind.',
            predict: 'Transaktionen aktualisieren',
            predictLastTime: 'Zuletzt',
            furtherPerformance: {
                header: 'Weitere Performance',
            },
            rules: {
                header: 'Regeln',
                stats: 'Anzahl von Regeln',
                depth: 'Tiefe der Regeln',
                ambiguity: 'Mehrdeutige Regeln',
            },
            schema: {
                info: 'Für eine schnelle, aber oberflächliche Zuordnung sollten nur ein oder zwei Merkmale ausgewählt werden (wie Kreditor und/oder Sachkonto). Für eine tiefgehende und detaillierte Zuordnung sind bis zu 4 Merkmale einschließlich einer Beschreibung sinnvoll. Die Merkmale können jederzeit angepasst werden.',
            },
            optionFilterOnConflicts: 'Nur Zeilen mit Konflikten',
            optionFilterOnNoPredictions: 'Nur Zeilen ohne CategoryLab-Vorhersagen',
        },
        performance: {
            stats: {
                info: 'Anteil von klassifizierten Spend und Transaktionen.',
                spend: 'Spend',
                spend_share: 'des Spends',
                transactions: 'Transaktionen',
                transaction_share: 'der Transaktionen',
            },
            depth: {
                info: 'Tiefe des klassifizierten Spend pro Warengruppe. Auf der linken Seite sind die Warengruppen nach ihrem relativen Anteil am Spend in % gelistet. Für jede Warengruppe ist die Tiefe der Klassifizierung (z.B. Level 1 bis Level 4) horizontal dargestellt. Für jedes Level wird der klassifizierte Anteil in % angegeben. Tiefe Klassifizierungen sind an kontrastreichen Farben auf der rechten Seite erkennbar. Je weiter oben sie liegen, desto mehr Spend ist davon betroffen. Wenn im Filter-Menü "Transaktionen" als Wert ausgewählt ist, beziehen sich alle relativen Angaben auf die Anzahl von Transaktionen statt auf den Spend.',
            },
            depthComparison: {
                info: 'Vergleich der Tiefe des klassifizierten Spend zwischen den verfügbaren Warengruppenanzeigen. Die Höhe jeder Säule stellt den gesamten Anteil des klassifizierten Spends dar. Die Zusammensetzung der Säule zeigt, wie sich der gesamte Anteil auf die unterschiedlichen Level im Warengruppenbaum verteilt. Wenn im Filter-Menü "Transaktionen" als Wert ausgewählt ist, beziehen sich alle relativen Angaben auf die Anzahl von Transaktionen statt auf den Spend.',
            },
            consistency: {
                info: 'Vergleich der Übereinstimmung zwischen den verfügbaren Warengruppenanzeigen. Für jede Anzeigemöglichkeit wird die Übereinstimmung mit allen weiteren Anzeigemöglichkeiten verglichen. Eine Übereinstimmung ist definiert, durch dieselbe oder eine tiefere Klassifizierung. Keine Übereinstimmung entspricht einer komplett anderen Zuordnung im Warengruppenbaum.',
            },
            ruleStats: {
                info: 'Anzahl von angelegten Regeln.',
                rules: 'Regeln',
            },
            ruleDepth: {
                info: 'Anteil der Regeln pro Hierarchie im Warengruppenbaum.',
            },
            ruleAmbiguity: {
                info: 'Die Anzahl der Regeln, die 1, 2, 3 oder mehr als 4 Level 1 Kategorien betreffen.',
            },
        },
        instructions: {
            headline: 'Video-Erläuterung zum CategoryLab',
            text: 'Hier erfährst du, wie das CategoryLab funktioniert und welche Möglichkeiten geboten werden.',
            ok: 'Zum Tool',
        },
        table: {
            labLoading: 'Bitte einen Moment Geduld. Dein individuelles CategoryLab wird vorbereitet.',
            labLoadingExplanation1: 'Das CategoryLab zeigt pro Transaktion (Bestellung oder Rechnung) eine Warengruppen-Vorhersage an.',
            labLoadingExplanation2: 'Diese Zuordnung kann auf Basis der verfügbaren Informationen bestätigt, geprüft oder tiefergelegt werden.',
            filterColumnContent: 'Filter nach Inhalt der Spalten',
            filterColumnContentLabel: 'Begriff eingeben',
            filterChosenCategory: 'Filter nach ausgewählter Warengruppe',
            filterChosenCategoryLabel: 'Nummer der Warengruppe eingeben',
            filterLanguages: 'Filter nach Sprachen',
            filterLanguagesLabel: 'Sprache Auswählen',
            onlyShowEmptyCategories: 'Nur leere Warengruppen-Felder anzeigen',
            doNotShowRowsWithRules: 'Zeilen mit Regeln ausblenden',
            onlyShowRowsWithRules: 'Nur Zeilen mit Regeln anzeigen',
            removeAllFilters: 'Alle Filter entfernen',
            hideFilterMenu: 'Filter-Menü schließen',
            ai: 'ki',
            changeAllocation: 'Zuordnung ändern',
            submitThisAllocation: 'Diese Zuordnung übernehmen',
            pleaseChoose: 'Bitte auswählen',
            chooseAllocation: 'Zuordnung vornehmen',
            resetRow: 'Zeile zurücksetzen',
            resetRowSuccess: 'Zeile erfolgreich zurückgesetzt',
            chooseCategory: 'Warengruppe zuordnen',
            selectAttributeFitting: 'Bitte wähle zunächst ein Merkmal aus, welches zu einer Warengruppe passt.',
            selectRow: 'Ordne alle Transaktionen hinter dieser Zeile zu einer Warengruppe zu.',
            selectRowWarning: 'Regeln können nicht mit einer direkten Zuordnung der Transaktionen kombiniert werden.',
            lostConnection: 'Leider wurde die Verbindung zum kiresult-Server unterbrochen. Bitte lade die Seite neu, damit die Funktionalität wieder gewährleistet ist.',
            furtherFilter: 'Weitere Filter',
            newRule: 'Neue Regel',
            existingRulesFor: 'Angelegte Regeln für',
            headerCategory: 'Warengruppe',
            headerSpend: 'Spend',
        },
        topDashboard: {
            autoRefresh: 'Automatische Aktualisierung',
            rowsWithRules: 'Zeilen mit Regeln',
            totalSpend: 'Gesamter Spend',
            totalTransactions: 'Gesamte Transaktionen',
            ofSpendAllocated: 'des Spends zugeordnet',
            ofTransactionsAllocated: 'der Transaktionen zugeordnet',
            aiReady: 'KI bereit',
            aiWorking: 'KI trainiert',
            aiLoading: 'KI lädt',
        },
        sidebar: {
            categorySupport: 'Warengruppen Support',
            onlyAI: 'nur KI',
            search: 'Suchen',
            searchWord: 'Suchbegriff',
            removeSelection: 'Zuordnung löschen',
            noCategorySelected: 'keine Warengruppe ausgewählt',
            selectCategoryFirst: 'Bitte wählen Sie zunächst eine Warengruppe aus',
            activeRule: 'Bearbeitete Regel',
        },
        hierarchyDialog: {
            headline: 'Bitte wähle aus, ob die Merkmale den richtigen Warengruppen-Levels zugeordnet sind.',
            column: 'Spalte',
            dragHere: 'Merkmale hierher ziehen',
            notUsedElements: 'Nicht genutzte Elemente',
            allocationDiffers: 'Abweichende Zuordnung',
            dragHereToDelete: 'Merkmale zum Löschen hierher ziehen',
            similarRules: 'Ähnliche gefundene Elemente',
        },
        valueTypeWarning: 'Die Performance der Klassifizierung wird nur auf Basis von Spend und nicht Transaktionen bestimmt.',
        predictions: {
            successMsg: 'Die Daten aus dem CategoryLab wurden übernommen.',
            statusMsg: 'Vorhersagen werden übernommen.',
            statusSlowMsg: 'Es dauert ein wenig länger als gedacht.',
        },
        settings: {
            schema: {
                header: 'CategoryLab Schema Konfiguration',
                attributes: 'Verfügbare Merkmale',
                selectedAttributes: 'Ausgewählte Merkmale',
                selectedAttributesPlaceholder: 'Merkmal zuordnen...',
                dropAttributes: 'Merkmale hierher ziehen',
            },
        },
        contextMenu: {
            copySingle: 'Kopieren (einzelnes Wort)',
            googleSingle: 'Google-Recherche (einzelnes Wort)',
            copyWhole: 'Kopieren (gesamter Text)',
            googleWhole: 'Google-Recherche (gesamter Text)',
        },
    },
    analytics: {
        common: {
            noDataAvailable: 'Keine Daten vorhanden',
            invoices: 'Rechnungen',
            orders: 'Bestellungen',
            compareChip: 'Im Vergleich zu Ø Stückpreis von',
        },
        timeSelection: {
            Gesamt: 'Gesamt',
            YTD: 'YTD',
            Jahr: 'Jahr',
            Quartal: 'Quartal',
            Eigen: 'Eigen',
            to: 'bis',
        },
        timeSelectionCompare: {
            'Keinen': 'Keinen',
            'YTD vor einem Jahr': 'YTD vor einem Jahr',
            'YTD im Jahr': 'YTD im Jahr...',
            'Jahr davor': 'Jahr davor',
            'Jahr': 'Jahr...',
            'Selbes im Vorjahr': 'Selbes im Vorjahr',
            'Quartal': 'Quartal...',
            'Selber letztes Jahr': 'Selber letztes Jahr',
            'Selber im Jahr': 'Selber im Jahr...',
        },
        filterPane: {
            dashboard: 'Dashboard',
            categoryLab: 'CategoryLab',
            vendorDetails: 'Lieferanten',
            categoryDetails: 'Warengruppen',
            spendDetails: 'Spend Analytics',
            savingsDetails: 'Savings Projekte',
            insightsDetails: 'Spend Insights',
            unitDetails: 'Einzelpreise',
            transactionDetails: 'Transaktionen',
            pivot: 'Pivot-Tabelle',
            kpiDetails: 'KPI-Dashboard',
            reportDetails: 'Reports',
            timePeriod: 'Zeitraum',
            choose: 'Auswählen',
            chooseTimePeriod: 'Zeitraum auswählen',
            comparePeriod: 'Vergleichszeitraum',
            chooseCompareTimePeriod: 'Vergleichszeitraum auswählen',
            category: 'Warengruppe',
            categories: 'Warengruppen',
            loading: 'Lädt...',
            foundNoResults: 'Keine Ergebnisse gefunden',
            listNotComplete: 'nicht vollständig',
            onlyTopElementsAreShown: 'In diesem Filter werden nur einige Elemente angezeigt.',
            onlyTopElementsAreShownSearch: 'In diesem Filter werden nur einige Elemente angezeigt. Du kannst die Suche nutzen, um alle Elemente zu finden.',
            removeAllFilters: 'Alle Filter und Zeitraum zurücksetzen',
            showFilterMenu: 'Filter-Menü zeigen',
            hideFilterMenu: 'Filter-Menü schließen',
            furtherFilters: 'Weitere Filter',
            showCategoryLabCategories: 'Art der Warengruppenanzeige',
            showCategoryLabCategoriesDisabled: 'CategoryLab deaktiviert',
            DISABLE: 'CategoryLab deaktiviert',
            showCategoryLabCategoriesDisabledTooltip: 'Die eigene Warengruppenzuordnung aus dem Daten-Upload wird genutzt.',
            showCategoryLabCategoriesTransactionBased: 'Transaktions-Basis',
            TRANSACTION_BASED: 'Transaktions-Basis',
            showCategoryLabCategoriesTransactionBasedTooltip: 'Pro Transaktion (Bestellung oder Rechnung) wird eine Warengruppe vorhergesagt.',
            showCategoryLabCategoriesVendorBased: 'Lieferanten-Basis',
            AGGREGATION_BASED: 'Lieferanten-Basis',
            showCategoryLabCategoriesVendorBasedTooltip: 'Pro Lieferant wird eine Warengruppe vorhergesagt.',
            showCategoryLabCategoriesJustTransactions: 'CategoryLab aktiviert',
            showCategoryLabCategoriesJustTransactionsTooltip: 'Es werden die Warengruppen angereichert durch das kiresult CategoryLab genutzt.',
            harmonization: 'Harmonisierung',
            valueTypeText: 'Werte anzeigen als',
            valueTypeSpend: 'Spend',
            valueTypeSpendTooltip: 'In allen Visualisierungen und Tabellen wird der jeweilige Spend angezeigt.',
            valueTypeTransactions: '# Transaktionen',
            valueTypeTransactionsTooltip: 'In allen Visualisierungen und Tabellen werden die Anzahl der dahinterliegenden Transaktionen angezeigt.',
            harmonizationWarning: 'Die Harmonisierung kann an dieser Stelle nur für Filter verwendet werden.',
            allSelect: '(Alle auswählen)',
            allSelected: 'Alle ausgewählt',
        },
        categoryDrillDialog: {
            headline: 'Bist du sicher, dass du nur auf diese Warengruppe filtern möchtest?',
            explaination: 'Damit werden alle anderen Warengruppen-Filter außer',
            removed: 'und Filterungen auf darunterliegenden Warengruppen-Levels entfernt.',
        },
        chartOptions: {
            spend: 'Spend',
            count: 'Transaktionen',
            day: 'Tage',
            week: 'Wochen',
            month: 'Monate',
            year: 'Jahre',
            DAY_singular: 'Tag',
            WEEK_singular: 'Woche',
            MONTH_singular: 'Monat',
            YEAR_singular: 'Jahr',
            noData: 'Keine Daten'
        },
        chartDescriptions: {
            totalSpend: 'Gesamter Spend',
            numberVendors: '# Lieferanten',
            numberTransactions: '# Transaktionen',
            developmentOverTime: 'Zeitliche Entwicklung',
            vendors: 'Lieferanten',
            vendorNumber: 'Kreditornummer',
        },
        bubbleCharts: {
            highRelevanceLowSpend: 'Große Relevanz - konsolidierter Spend',
            highTransactionsFewVendors: 'Viele Transaktionen - wenige Lieferanten',
            highRelevanceHighSpend: 'Große Relevanz - breit gestreuter Spend',
            highTransactionsManyVendors: 'Viele Transaktionen - viele Lieferanten',
            lowRelevanceLowSpend: 'Geringe Relevanz - konsolidierter Spend',
            lowTransactionsFewVendors: 'Wenige Transaktionen - wenige Lieferanten',
            lowRelevanceHighSpend: 'Geringe Relevanz - breit gestreuter Spend',
            lowRelevanceManyVendors: 'Wenige Transaktionen - viele Lieferanten',
        },
        tableHeaders: {
            category: 'Warengruppe',
            vendor: 'Lieferant',
            vendorNumber: 'Nummer',
            vendorName: 'Lieferant-Name',
            spend: 'Spend',
            details: 'Details',
            numberTransactions: '# Transaktionen',
            spendPerTransaction: 'Spend pro Transaktion',
            name: 'Name',
            submit: 'Übernehmen',
            classification: 'Einordnung',
            actions: 'Aktionen',
            putToFilter: 'Als Filter übernehmen',
            goToDetailPage: 'Auf Detailseite springen',
        },
        dashboard: {
            hello: 'Hallo',
            subtitle: 'Übersicht über deinen Spend',
            availableTimeRange: 'Verfügbarer Zeitraum',
        },
        transactions: {
            furtherOptions: 'Weitere Optionen anzeigen',
            empty: 'Keine Transaktionen gefunden',
        },
        quantities: {
            loadQuantityInformation: 'Mengeninformationen',
            noQuantityInformationAvailable: 'Keine Mengeninformationen verfügbar',
            helpForFilters: 'Bitte wähle zuerst im Filtermenü (oben) ein Material oder eine detaillierte Warengruppe aus, um eine vergleichbare Stückpreisanalyse durchzuführen. Anschließend wähle eine genaue Mengeneinheit (unten) aus.',
            helpForFiltersSmall: 'Für eine vergleichbare Stückpreisanalyse sollte ein Material oder eine detaillierte Warengruppe ausgewählt werden.',
            units: 'Mengeneinheiten',
            unitPriceAnalysis: 'Stückpreisanalyse',
            timeAggregation: 'Zeit-Aggregation',
            selectUnit: 'Wähle eine Mengeneinheit',
            unit: 'Mengeneinheit',
            quantity: 'Menge',
            spendPerUnit: 'Spend pro Mengeneinheit',
            averageUnitPrice: 'Ø Stückpreis (real)',
            spend: 'Spend',
            tableTimeAndGap: {
                title: 'Zeitliche Entwicklung & Gap-Analyse',
                header: {
                    time: 'Zeitraum',
                    unitPrice: 'Stückpreis',
                    compareTime: 'Vergleichszeitraum',
                },
                compareToTimeStep: 'im Vergleich zum vorherigen Schritt mit',
                compareToComparePeriod: 'im Vergleich zum Vergleichszeitraum mit',
            },
            tableDimensions: {
                header: {
                    name: 'Name',
                    quantity: 'Menge',
                    spend: 'Spend',
                    count: 'Transaktionen',
                    unitPrice: 'Stückpreis',
                },
            },
        },
        components: {
            shownLevel: 'angezeigtes Level',
            detailViewCategoryLevels: 'Detailansicht für Warengruppenstufen',
            detailReports: 'Detailauswertungen',
            categoryDetails: 'Warengruppen-Details',
            evaluationOverAllCategories: 'Auswertung über alle Warengruppen',
            vendorOverview: 'Lieferantenübersicht',
            detailAnalyticsVendorsInCategories: 'Detailanalyse Lieferantenstruktur innerhalb der Warengruppen',
            ofSpend: 'des Spends',
            with: 'mit',
            vendors: 'Lieferanten',
            vendorDetails: 'Lieferanten-Details',
            evaluationOverAllVendors: 'Auswertung über alle Lieferanten ',
            searchForVendors: 'Nach Lieferanten suchen...',
            resultsFor: 'Deine Ergebnisse für',
            abcOverview: 'ABC Übersicht',
            allVendors: 'Alle Lieferanten',
            transactionOverview: 'Transaktionsübersicht',
        },
    },
    insights: {
        betaWarning: 'Bei den hier angezeigten Initiativen und Daten handelt es sich um eine Preview-Version auf Demo-Daten. Sie sind also noch nicht auf das eigene Unternehmen angepasst.',
    },
    report: {
        standardTitle: 'Standardreports',
        standardDescription: 'Einzelne Transaktionen exportieren. Alle oben gesetzten Filter werden vor dem Download angewendet.',
        excel: {
            title: 'Excel-Export',
            description: 'Einzelne Transaktionen exportieren. Alle oben gesetzten Filter werden vor dem Download angewendet.',
        },
        csv: {
            title: 'CSV-Export',
            description: 'Einzelne Transaktionen exportieren. Alle oben gesetzten Filter werden vor dem Download angewendet. Der CSV-delimiter ist ein Semikolon.',
        },
        json: {
            title: 'JSON-Export',
            description: 'Einzelne Transaktionen exportieren. Alle oben gesetzten Filter werden vor dem Download angewendet.',
        },
        download: 'Download',
        downloadSuccess: 'Der Download des Reports wurde angestoßen. Der Report wird dir per E-Mail zugesendet.',
        customTitle: 'Individuelle Reports',
        customNoReports: 'Es sind noch keine individuellen Reports vorhanden.',
    },
    documentation: {
        'headline': 'Dokumentation & FAQ',
        'headlineVideoTours': 'Video-Touren durch die kiresult-Plattform',
    },
    enums: {
        INVOICE: 'Rechnungen',
        ORDER: 'Bestellungen',
        true: 'Ja',
        false: 'Nein',
        SPEND: 'Spend',
        COUNT: '# Transaktionen',
        category: {
            'CUSTOMER': 'CategoryLab deaktiviert',
            'BEST': 'CategoryLab aktiviert',
            'BEST_AGGREGATED': 'Lieferanten-Basis',
        },
    },
    dataStructure: {
        'identifier': 'Identifier (ki)',
        'order_identifier': 'Bestell-/Belegnummer',
        'order_position': 'Bestell-/Belegposition',
        'order_code': 'Bestell-/Belegkennzeichen',
        'order_type': 'Bestell-/Belegart',
        'order_or_invoice': 'Bestellung oder Rechnung',
        'invoice_identifier': 'Rechnungsnummer',
        'order_reference': 'Bestellreferenz',
        'has_order_reference': 'Bestellreferenz vorhanden?',
        'intercompany': 'Intercompany',
        'addressable': 'Adressierbarer Spend?',
        'operative': 'Operativer Spend?',
        'touched': 'Touched Spend?',
        'annex_identifier': 'Anlagennummer',
        'timestamp': 'Bestell-/Belegdatum',
        'timestamp_order': 'Bestelldatum',
        'timestamp_invoice': 'Rechnungsdatum',
        'timestamp_invoice_due_date': 'Rechnungs-Fälligkeitsdatum',
        'timestamp_posting': 'Buchungsdatum',
        'timestamp_payment': 'Zahlungsdatum',
        'timestamp_delivery': 'Lieferdatum',
        'timestamp_delivery_due_date': 'Lieferung-Fälligkeitsdatum',
        'timestamp_running_time_start': 'Leistungszeitraum Beginn',
        'timestamp_running_time_end': 'Leistungszeitraum Ende',
        'time_invoice_to_invoice_due_date': 'Zeit von Rechnung bis Fälligkeit',
        'time_invoice_to_posting': 'Zeit von Rechnung bis Buchung',
        'payment_on_time': 'Zahlung on-time?',
        'spend': 'Spend (Netto)',
        'currency': 'Währung',
        'spend_per_quantity': 'Einzelpreis (Netto)',
        'quantity': 'Menge',
        'quantity_supplied': 'Menge geliefert',
        'quantity_invoiced': 'Menge fakturiert',
        'quantity_unit': 'Mengeneinheit',
        'weight': 'Gewicht',
        'tax_rate': 'Steuerkennzeichen',
        'terms_of_payment': 'Zahlungsbedingung',
        'skonto_amount': 'Skontobetrag',
        'skonto_rate': 'Skontosatz in %',
        'has_skonto': 'Skontobetrag vorhanden?',
        'skonto_taken': 'Skonto gezogen?',
        'factory': 'Werk',
        'direct_indirect': 'Direkt/Indirekt',
        'incoterm': 'Incoterm',
        'description': 'Beschreibung',
        'description_short': 'Beschreibung (kurz)',
        'description_long': 'Beschreibung (lang)',
        'purchasing_group': 'Einkäufergruppe',
        'purchasing_organization': 'Einkäuferorganisation',
        'business_unit': 'Geschäftsbereich',
        'business_unit_board': 'Verantwortlicher',
        'monitoring_regulation': 'MVO',
        'monitoring_regulation_hier2': 'MVO Lvl. 2',
        'organization': 'Organization',
        'period_accrual': 'Periodenabgrenzung',
        'vendor_general_ledger': 'Kreditor | Sachkonto',
        'country_vendor': 'Kreditor Land',
        'country_of_delivery': 'Land des Lieferorts',
        'type_of_material': 'Materialart',
        'country_company_code': 'Buchungskreis Land',
        'corporate_group': 'Konzern',
        'status': 'Status',
        'requester': 'Anforderer',
        'responsible': 'Verantwortlicher Einkäufer',
        'category_secondary_name': 'Interne Warengruppe',
        'has_category': 'Kundenwarengruppe vorhanden?',
        'country': 'Länderkennzeichen',
        'line_number': 'Zeilennummer',
        'capital_operating_expenses': 'Capex/Opex',
        'cost_type': 'Kostenart',
        'lang': 'Sprache der Transaktion',
        'file_ids': 'Datenextrakt',
        'type': 'Transaktionstyp',
        'posting_key': 'Buchungsschlüssel',
        'posting_period': 'Buchungsperiode',
        'cp': 'CP-Bereich',
        'segment': 'Segment',
        'contract': 'Vertrag',
        'contract___ewmr': 'Kontrakt',
        'assignment': 'Auftrag',
        'relevant_strategic': 'Strategische Relevanz',
        'via_eprocurement': 'über eProcurement?',
        'via_catalog': 'über Katalog?',
        'delivery_on_time': 'Lieferung on-time?',
        'has_contract': 'Vertragsbezug?',
        'has_contract___ewmr': 'Kontraktbezug?',
        'delivery_condition': 'Lieferbedingung',
        'scope_poc': 'Scope Proof of Concept',
        'name': 'Projektname',
        'non_financial_benefits': 'Nicht-finanzielle Benefits',
        'non_financial_benefits_description': 'Beschreibung für nicht-finanzielle Benefits',
        'savings_missed_reason': 'Grund für fehlende Savings',
        'responsible_email': 'E-Mail des Verantwortlichen',
        'tariff_number': 'Zolltarifnummer',
        'gtin': 'GTIN',
        'cas_number': 'CAS-Nummer',
        'placeholder_1': 'Platzhalter 1',
        'placeholder_2': 'Platzhalter 2',
        'placeholder_3': 'Platzhalter 3',
        'placeholder_4': 'Platzhalter 4',
        'placeholder_5': 'Platzhalter 5',
        'placeholder_6': 'Platzhalter 6',
        'placeholder_7': 'Platzhalter 7',
        'placeholder_8': 'Platzhalter 8',
        'placeholder_9': 'Platzhalter 9',
        'vendor_id': 'Kreditor',
        'vendor': {
            'name': 'Kreditorname',
            'identifier': 'Kreditornummer',
            'country': 'Kreditor Land',
            'city': 'Kreditor Ort',
            'zip_code': 'Kreditor PLZ',
        },
        'category_tag': 'Kontext (Beta)',
        'category_id': 'Warengruppe',
        'category': {
            'name': 'Warengruppenname',
            'identifier': 'Warengruppennummer',
        },
        'general_ledger_id': 'Sachkonto',
        'general_ledger': {
            'name': 'Sachkontoname',
            'identifier': 'Sachkontonummer',
        },
        'profit_center_id': 'Profitcenter',
        'profit_center': {
            'name': 'Profitcentername',
            'identifier': 'Profitcenternummer',
        },
        'company_code_id': 'Buchungskreis',
        'company_code': {
            'name': 'Buchungskreisname',
            'identifier': 'Buchungskreisnummer',
        },
        'material_id': 'Material',
        'material': {
            'name': 'Materialname',
            'identifier': 'Materialnummer',
        },
        'cost_center_id': 'Kostenstelle',
        'cost_center': {
            'name': 'Kostenstellenname',
            'identifier': 'Kostenstellennummer',
        },
        'cost_unit_id': 'Kostenträger',
        'cost_unit': {
            'name': 'Kostenträgername',
            'identifier': 'Kostenträgernummer',
        },
        'processOverview': 'Prozess-Einblicke',
    },
    collection: {
        'vendor': 'Kreditor',
        'general_ledger': 'Sachkonto',
        'material': 'Material',
        'cost_center': 'Kostenstelle',
        'cost_unit': 'Kostenträger',
        'company_code': 'Buchungskreis',
        'profit_center': 'Profitcenter',
        'category': 'Warengruppe',
        'add': {
            'number': '-Nummer',
            'name': '-Name',
        },
    },
    refresh: {
        msg: 'Aktualisiere die Seite, um die neuesten Updates zu sehen.',
        button: 'Seite neu laden',
    },
    kpi: {
        spendCountToggleWarning: 'Die Wahl der Werte hat keinen Effekt auf dieser Seite',
        module: {
            timeHorizon: 'Zeit-Horizont',
            messageInYearTotal: 'im Gesamtjahr (keine Zeitfilterung)',
            errorMessageImpossibleToCalculate: 'Es ist nicht möglich diese KPI zu kalkulieren',
            kpiValue: 'KPI-Wert',
            per: 'pro',
            targetValue: 'Zielwert',
            benchmark: 'Benchmark',
            benchmarkValue: 'Benchmark-Wert',
            target: 'Ziel',
            toTimerangeComparison: 'zum Vergleichszeitraum',
            totalNumberOfBudgetExceeding: 'Gesamte Anzahl an Budgetüberschreitungen',
            messageBudgetsOnlyPerYear: 'Budgets können lediglich auf Jahresebene angezeigt werden. Bitte wähle also den Zeitraum YTD oder Jahr aus.',
            messageBudgetsNotAvailable: 'Für dieses Jahr ist noch kein Budget angelegt.',
            messageBudgetsPleaseExpand: 'Bitte ausklappen, um weitere Informationen zu den Budgets zu erhalten.',
            messageBudgtesCountTotalOverBudget: 'Anzahl gesamte Budgetüberschreitungen',
            messagePleaseChooseADimension: 'Bitte eine Dimension auswählen, um weitere Infos zu erhalten',
            removeKpiAsFavorite: 'Diese KPI als Favorit entfernen',
            markKpiAsFavorite: 'Diese KPI als Favorit markieren',
            trend: 'Trend',
            messageNoTargetDefined: 'Es ist noch kein Ziel für diese KPI und Zeitraum festgelegt',
            descriptionOfKPI: 'Beschreibung der Kennzahl',
            calculationOfKPI: 'Berechnung der Kennzahl',
            showDetails: 'Zeige Details',
            reset: 'Zurücksetzen',
            detailedAnalysis: 'Detailanalyse der Kennzahl',
        },
        kpiDetailMenu: {
            editTargets: 'Ziele bearbeiten',
            editTargetsFor: 'Ziele anpassen für',
            showTargets: 'Ziele anzeigen',
            addTarget: 'Ziel hinzufügen',
            addTargetFor: 'Ziel hinzufügen für',
            editTarget: 'Ziel bearbeiten',
            editTargetFor: 'Ziel bearbeiten für',
            sureToRemove: 'Bist du dir sicher, dass du dieses Ziel löschen möchtest?',
            timeBeginTarget: 'Zeitpunkt Beginn der Geltung',
            targetForSuccess: 'Zielwert für Erfolg',
            tresholdForFailure: 'Grenzwert für Misserfolg',
            refersToTimeHorizon: 'Bezieht sich auf Zeit-Horizont:',
            selectTime: 'Bitte einen Zeitpunkt auswählen',
            add: 'Hinzufügen',
            update: 'Aktualisieren',
        },
        kpiDimensionTable: {
            showInTimeManner: 'Dieses Element im Zeitverlauf anzeigen',
        },
        kpiInfo: {
            kpi_transactions: {
                'name': 'Anzahl Transaktionen',
                'description': 'Gesamtanzahl der Transaktionszeilen (z.B. Bestell- oder Rechnungszeilen) des gefilterten Scopes.',
                'formula': '# Transaktionen',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_spend: {
                'name': 'Spend',
                'description': 'Summe der Gesamtausgaben/Kosten (Rechnungen: Summe aller Rechnungsbeträge / Bestellungen: Summe aller Bestellpositionen.',
                'formula': '∑ Spend',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_average_spend_per_transaction: {
                'name': '⌀ Spend pro Transaktion',
                'description': 'Durchschnittlicher Wert einer Bestell-/Rechnungszeile.',
                'formula': '(∑ Spend) / (∑ Anzahl Transaktionen)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_transactions_with_order_reference: {
                'name': 'Anzahl Transaktionen mit Bestellbezug',
                'description': 'Anzahl der Rechnungspositionen mit Referenz zu einer Bestellung.',
                'formula': '∑ Anzahl Transaktionen -> Filter mit Bestellbezug',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_transactions_without_order_reference: {
                'name': 'Anzahl Transaktionen ohne Bestellbezug',
                'description': ' Anzahl der Rechnungspositionen ohne Referenz zu einer Bestellung (Indikator für Maverick Buying).',
                'formula': '∑ Anzahl Transaktionen -> Filter ohne Bestellbezug',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_spend_with_order_reference: {
                'name': 'Spend mit Bestellbezug',
                'description': 'Gesamtwert von Rechnungen mit Bestellbezug.',
                'formula': '∑ Spend -> Filter mit Bestellbezug',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_spend_without_order_reference: {
                'name': 'Spend ohne Bestellbezug',
                'description': 'Gesamtwert von Rechnungen ohne Bestellbezug',
                'formula': '∑ Spend -> Filter ohne Bestellbezug',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_transactions_with_order_reference: {
                'name': 'Anteil Transaktionen mit Bestellbezug',
                'description': 'Relativer Anteil von Rechnungspositionen mit Bezug zu einer Bestellung.',
                'formula': '(# Transaktionen -> Filter mit Bestellbezug) / (# Transaktionen)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_transactions_without_order_reference: {
                'name': 'Anteil Transaktionen ohne Bestellbezug',
                'description': 'Relativer Anteil von Rechnungspositionen ohne Bezug zu einer Bestellung.',
                'formula': '(# Transaktionen -> Filter ohne Bestellbezug) / (# Transaktionen)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_spend_with_order_reference: {
                'name': 'Anteil Spend mit Bestellbezug',
                'description': 'Relativer Anteil des Gesamtwertes von Rechnungen mit Bestellbezug',
                'formula': '(∑ Spend -> Filter mit Bestellbezug) / (∑ Spend)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_spend_without_order_reference: {
                'name': 'Anteil Spend ohne Bestellbezug',
                'description': 'Relativer Anteil des Gesamtwertes von Rechnungen ohne Bestellbezug (Indikator für Maverick Buying).',
                'formula': '(∑ Spend -> Filter ohne Bestellbezug) / (∑ Spend)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
			kpi_share_transactions_with_order_reference_without_refund: {
                'name': 'Anteil Transaktionen mit Bestellbezug (ohne Gutschriften)',
                'description': 'Relativer Anteil von Rechnungspositionen mit Bezug zu einer Bestellung.',
                'formula': '(# Transaktionen -> Filter mit Bestellbezug) / (# Transaktionen)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_transactions_without_order_reference_without_refund: {
                'name': 'Anteil Transaktionen ohne Bestellbezug (ohne Gutschriften)',
                'description': 'Relativer Anteil von Rechnungspositionen ohne Bezug zu einer Bestellung.',
                'formula': '(# Transaktionen -> Filter ohne Bestellbezug) / (# Transaktionen)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_spend_with_order_reference_without_refund: {
                'name': 'Anteil Spend mit Bestellbezug (ohne Gutschriften)',
                'description': 'Relativer Anteil des Gesamtwertes von Rechnungen mit Bestellbezug',
                'formula': '(∑ Spend -> Filter mit Bestellbezug) / (∑ Spend)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_spend_without_order_reference_without_refund: {
                'name': 'Anteil Spend ohne Bestellbezug (ohne Gutschriften)',
                'description': 'Relativer Anteil des Gesamtwertes von Rechnungen ohne Bestellbezug (Indikator für Maverick Buying).',
                'formula': '(∑ Spend -> Filter ohne Bestellbezug) / (∑ Spend)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_unique_vendor: {
                'name': 'Anzahl insgesamt angelegter Lieferanten',
                'description': 'Gesamtanzahl der verschiedenen in den Daten vorhandenen Lieferanten, ohne Zeitfilterung.',
                'formula': '# Lieferanten ohne Zeitfilter -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_unique_general_ledger: {
                'name': 'Anzahl insgesamt genutzter Sachkonten',
                'description': 'Gesamtanzahl der verschiedenen in den Daten vorhandenen Sachkonten, ohne Zeitfilterung.',
                'formula': '# Sachkonten ohne Zeitfilter -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_unique_factory: {
                'name': 'Anzahl insgesamt genutzter Werke',
                'description': 'Gesamtanzahl der verschiedenen in den Daten vorhandenen Werke, ohne Zeitfilterung.',
                'formula': '# Werke ohne Zeitfilter -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_unique_purchasing_organization: {
                'name': 'Anzahl insgesamt genutzter Einkäuferorganisationen',
                'description': 'Gesamtanzahl der verschiedenen in den Daten vorhandenen Einkäuferorganisationen, ohne Zeitfilterung.',
                'formula': '# Einkäuferorganisationen ohne Zeitfilter -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_unique_purchasing_group: {
                'name': 'Anzahl insgesamt genutzter Einkäufergruppen',
                'description': 'Gesamtanzahl der verschiedenen in den Daten vorhandenen Einkäufergruppen, ohne Zeitfilterung.',
                'formula': '# Einkäufergruppen ohne Zeitfilter -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_unique_category: {
                'name': 'Anzahl insgesamt genutzter Warengruppen',
                'description': 'Gesamtanzahl der verschiedenen in den Daten vorhandenen Warengruppen, ohne Zeitfilterung.',
                'formula': '# Warengruppen ohne Zeitfilter -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_unique_profit_center: {
                'name': 'Anzahl insgesamt genutzter Profit Center',
                'description': 'Gesamtanzahl der verschiedenen in den Daten vorhandenen Profit Center, ohne Zeitfilterung.',
                'formula': '# Profit Center ohne Zeitfilter -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_unique_company_code: {
                'name': 'Anzahl insgesamt genutzter Buchungskreise',
                'description': 'Gesamtanzahl der verschiedenen in den Daten vorhandenen Buchungskreise, ohne Zeitfilterung.',
                'formula': '# Buchungskreise ohne Zeitfilter -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_unique_cost_center: {
                'name': 'Anzahl insgesamt genutzter Kostenstellen',
                'description': 'Gesamtanzahl der verschiedenen in den Daten vorhandenen Kostenstellen, ohne Zeitfilterung.',
                'formula': '# Kostenstellen ohne Zeitfilter -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_unique_cost_unit: {
                'name': 'Anzahl insgesamt genutzter Kostenträger',
                'description': 'Gesamtanzahl der verschiedenen in den Daten vorhandenen Kostenträger, ohne Zeitfilterung.',
                'formula': '# Kostenträger ohne Zeitfilter -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_unique_material: {
                'name': 'Anzahl insgesamt genutzter Materialien',
                'description': 'Gesamtanzahl der verschiedenen in den Daten vorhandenen Materialen, ohne Zeitfilterung.',
                'formula': '# Materialien ohne Zeitfilter -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_vendor_per_mio_spend: {
                'name': 'Anzahl aktiver Lieferanten pro 1 Mio. € Spend',
                'description': 'Anzahl aktiver Lieferanten je 1 Millionen Euro Spend.',
                'formula': '(# Lieferanten) / (∑ Spend) * 1.000.000 ',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_vendor: {
                'name': 'Anzahl aktiver Lieferanten',
                'description': 'Anzahl der verschiedenen Lieferanten, die im ausgewählten Zeitraum Transaktionsvolumen aufweisen.',
                'formula': '# Lieferanten -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_general_ledger: {
                'name': 'Anzahl aktiver Sachkonten',
                'description': 'Anzahl der verschiedenen Sachkonten, die im ausgewählten Zeitraum Transaktionsvolumen aufweisen.',
                'formula': '# Sachkonten -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_factory: {
                'name': 'Anzahl aktiver Werke',
                'description': 'Anzahl der verschiedenen Werke, die im ausgewählten Zeitraum Transaktionsvolumen aufweisen.',
                'formula': '# Werke -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_purchasing_organization: {
                'name': 'Anzahl aktiver Einkäuferorganisationen',
                'description': 'Anzahl der verschiedenen Einkaufsorganisationen, die im ausgewählten Zeitraum Transaktionsvolumen aufweisen.',
                'formula': '# Einkäuferorganisationen -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_purchasing_group: {
                'name': 'Anzahl aktiver Einkäufergruppen',
                'description': 'Anzahl der verschiedenen Einkäufergruppen, die im ausgewählten Zeitraum Transaktionsvolumen aufweisen.',
                'formula': '# Einkäufergruppen -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_category: {
                'name': 'Anzahl aktiver Warengruppen',
                'description': 'Anzahl der verschiedenen Warengruppen, die im ausgewählten Zeitraum Transaktionsvolumen aufweisen.',
                'formula': '# Warengruppen -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_profit_center: {
                'name': 'Anzahl aktiver Profit Center',
                'description': 'Anzahl der verschiedenen von Profit Centern, die im ausgewählten Zeitraum Transaktionsvolumen aufweisen.',
                'formula': '# Profit Center -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_company_code: {
                'name': 'Anzahl aktiver Buchungskreise',
                'description': 'Anzahl der verschiedenen Buchungskreise, die im ausgewählten Zeitraum Transaktionsvolumen aufweisen.',
                'formula': '# Buchungskreise -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_cost_center: {
                'name': 'Anzahl aktiver Kostenstellen',
                'description': 'Anzahl der verschiedenen Kostenstellen, die im ausgewählten Zeitraum Transaktionsvolumen aufweisen.',
                'formula': '# Kostenstellen -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_cost_unit: {
                'name': 'Anzahl aktiver Kostenträger',
                'description': 'Anzahl der verschiedenen Kostenträger, die im ausgewählten Zeitraum Transaktionsvolumen aufweisen.',
                'formula': '# Kostenträger -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_material: {
                'name': 'Anzahl aktiver Materialien',
                'description': 'Anzahl der verschiedenen Materialien, die im ausgewählten Zeitraum Transaktionsvolumen aufweisen.',
                'formula': '# Materialien -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_count_order_reference: {
                'name': 'Anzahl verschiedene Bestellungen',
                'description': 'Anzahl der verschiedenen Bestellungen, die im ausgewählten Zeitraum Transaktionsvolumen aufweisen.',
                'formula': '# Bestellnummern -> Duplikate entfernt',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_average_time_diff_invoice_to_posting: {
                'name': 'Durchschnittliche Dauer von Rechnung bis zur Rechnungsbuchung',
                'description': 'Durchschnittlicher Zeitraum von Rechnungsdatum bis Rechnungsbuchung.',
                'formula': 'Ø (Datumsdifferenz Rechnungseingang bis Rechnungsbuchung)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_average_time_diff_order_to_delivery: {
                'name': 'Durchschnittliche Dauer von Bestellung bis Wareneingang',
                'description': 'Durchschnittliche Zeit von Bestellung bis Eingang der Ware, bzw. Erhalt der Dienstleistung.',
                'formula': ' Ø (Datumsdifferenz Bestellung bis Wareneingang)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_average_time_diff_delivery_to_delivery_due_date: {
                'name': 'Abweichung Wareneingang von Wareneingang-Fälligkeitsdatum',
                'description': 'Durchschnittliche Abweichung des Wareneingangs von Wareneingang-Fälligkeitsdatum.',
                'formula': ' Ø (Datumsdifferenz Wareneingang bis Wareneingang-Fälligkeitsdatum)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_average_time_diff_invoice_to_payment: {
                'name': 'Durchschnittliche Dauer von Rechnung bis zur Rechnungszahlung',
                'description': 'Durchschnittlicher Zeitraum von Rechnungsdatum bis zur Zahlung der Rechnung.',
                'formula': ' Ø (Datumsdifferenz Rechnungseingang bis Rechnungszahlung)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_average_time_diff_invoice_to_invoice_due_date: {
                'name': 'Durchschnittliches Zahlungsziel',
                'description': 'Durchschnittlich vereinbarte Zeit von Rechnungsdatum bis zur Zahlung von Rechnungen (Zahlungsziel).',
                'formula': ' Ø (Datumsdifferenz Rechnungsdatum bis Rechnungs-Fälligkeitsdatum)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_average_time_diff_payment_to_invoice_due_date: {
                'name': 'Abweichung Rechnungszahlung von Rechnungsfälligkeit',
                'description': 'Durchschnittliche Abweichung der Rechnungszahlung von Rechnungsfälligkeit.',
                'formula': ' Ø (Datumsdifferenz Rechnungszahlung bis Rechnungs-Fälligkeitsdatum)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_transactions_skonto: {
                'name': 'Anzahl Transaktionen mit Skonto',
                'description': 'Gesamtanzahl von Rechnungspositionen mit gewährtem Skonto (Skontopotenzial).',
                'formula': '# Transaktionen -> Filter Skonto vorhanden',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_transactions_with_skonto_taken: {
                'name': 'Anzahl Transaktionen mit gezogenem Skonto',
                'description': 'Gesamtanzahl von Rechnungspositionen mit gezogenem Skonto (gemäß vereinbarten Zahlungsbedingungen).',
                'formula': '# Transaktionen -> Filter Skonto vorhanden & Skonto gezogen',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_transactions_without_skonto_taken: {
                'name': 'Anzahl Transaktionen ohne gezogenes Skonto',
                'description': 'Gesamtanzahl von Rechnungspositionen, für welche vorhandenes Skontopotenzial nicht gezogen wurde.',
                'formula': '# Transaktionen -> Filter Skonto vorhanden & Skonto nicht gezogen',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_transactions_skonto: {
                'name': 'Anteil Transaktionen mit Skonto',
                'description': 'Relativer Anteil von Rechnungspositionen mit gewährtem Skonto (Skontopotenzial).',
                'formula': '(# Transaktionen -> Filter Skonto vorhanden) / (# Transaktionen)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_transactions_with_skonto_taken: {
                'name': 'Anteil Transaktionen mit gezogenem Skonto',
                'description': 'Relativer Anteil von Rechnungspositionen mit gezogenem Skonto (gemäß vereinbarten Zahlungsbedingungen).',
                'formula': '(# Transaktionen -> Filter Skonto vorhanden & Skonto gezogen) / (# Transaktionen)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_transactions_without_skonto_taken: {
                'name': 'Anteil Transaktionen ohne gezogenes Skonto',
                'description': 'Relativer Anteil von Rechnungspositionen, für welche vorhandenes Skontopotenzial nicht gezogen wurde.',
                'formula': '(# Transaktionen -> Filter Skonto vorhanden & Skonto nicht gezogen) / (# Transaktionen)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_skonto_amount: {
                'name': 'Gesamtsumme Skonto-Betrag',
                'description': 'Summe des möglichen Skontos bei Transaktionen, bei denen Skonto vereinbart wurde.',
                'formula': '∑ Skonto-Betrag -> Filter Skonto vorhanden',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_skonto_amount_with_skonto_taken: {
                'name': 'Summe gezogenes Skonto',
                'description': 'Summe des Skontos, das durch rechtzeitige Zahlung gezogen wurde.',
                'formula': '∑ Skonto-Betrag -> Filter Skonto vorhanden & Skonto gezogen',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_skonto_amount_without_skonto_taken: {
                'name': 'Summe nicht gezogenes Skonto',
                'description': 'Summe des Skontos, das nicht durch rechtzeitige Zahlung gezogen wurde.',
                'formula': '∑ Skonto-Betrag -> Filter Skonto vorhanden & Skonto nicht gezogen',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_skonto_amount_with_skonto_taken: {
                'name': 'Anteil gezogenes Skonto vom gesamten Skontobetrag',
                'description': 'Relativer Anteil des gezogenen Skontos vom gesamten möglichen Skontobetrag.',
                'formula': '(∑ Skonto-Betrag -> Filter Skonto vorhanden & Skonto gezogen) / (∑ Skonto-Betrag -> Filter Skonto vorhanden)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_skonto_amount_without_skonto_taken: {
                'name': 'Anteil nicht gezogenes Skonto vom gesamten Skontobetrag',
                'description': 'Relativer Anteil des nicht gezogenen Skontos vom gesamten möglichen Skontobetrag (auch Skontopotenzial genannt).',
                'formula': '(∑ Skonto-Betrag -> Filter Skonto vorhanden & Skonto nicht gezogen) / (∑ Skonto-Betrag -> Filter Skonto vorhanden)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_average_skonto_rate_from_spend: {
                'name': '⌀ Skonto-Prozentsatz',
                'description': 'Durchschnittlicher Skontosatz (abzugsberechtigter Anteil bei frühzeitiger Rechnungszahlung).',
                'formula': ' Ø Skonto-Prozentsatz -> Filter Skonto vorhanden',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_transactions_per_employee: {
                'name': 'Transaktionen pro Mitarbeiter im Einkauf',
                'description': 'Durchschnittliche Anzahl von Rechnungs-/Bestellpositionen je EinkaufsmitarbeiterIn.',
                'formula': ' # Transaktionen / # Mitarbeiter im Einkauf',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_spend_per_employee: {
                'name': 'Spend pro Mitarbeiter im Einkauf',
                'description': 'Durchschnittliches Bestell-/Rechnungsvolumen je EinkaufsmitarbeiterIn.',
                'formula': ' ∑ Spend / # Mitarbeiter im Einkauf',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'TRUE',
                'roundDigits': '0',
            },
            kpi_spend_of_revenue: {
                'name': 'Spend vom Umsatz',
                'description': 'Relativer Anteil der Gesamtausgaben vom Umsatz.',
                'formula': ' ∑ Spend / Jahresumsatz',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NO_TIME_FILTER',
                'requireFact': 'TRUE',
                'roundDigits': '0',
            },
            kpi_spend_budget_deviation_share: {
                'name': 'Prozentuale Budgetabweichung',
                'description': 'Relative Abweichung des Bestell-/Rechnungsvolumens vom Budget im angegebenen Jahr.',
                'formula': ' ((∑ Spend) - (∑ Budget)) / (∑ Budget)',
                'customFrontendHandling': 'TRUE',
                'customFrontendHandlingType': 'BUDGET',
                'requireFact': 'TRUE',
                'roundDigits': '0',
            },
            kpi_spend_budget_deviation_total: {
                'name': 'Summe Budgetabweichung',
                'description': 'Volumen der Bestell-/Rechnungswerte, die oberhalb des für das Jahr definierten Budgets liegen.',
                'formula': ' (∑ Spend) - (∑ Budget)',
                'customFrontendHandling': 'TRUE',
                'customFrontendHandlingType': 'BUDGET',
                'requireFact': 'TRUE',
                'roundDigits': '0',
            },
            kpi_spend_over_budget_count: {
                'name': 'Anzahl Budgetüberschreitungen',
                'description': 'Anzahl der für das gefilterte Jahr definierten Budgets, die überschritten sind.',
                'formula': '∑ einzelner Dimensionen -> Filter auf Budgetüberschreitung',
                'customFrontendHandling': 'TRUE',
                'customFrontendHandlingType': 'DO_NOT_SHOW',
                'requireFact': 'TRUE',
                'roundDigits': '0',
            },
            kpi_spend_with_addressable: {
                'name': 'Adressierbarer Spend',
                'description': '',
                'formula': '∑ Spend -> Filter mit Adressierbar',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_spend_with_touched: {
                'name': 'Touched Spend',
                'description': '',
                'formula': '∑ Spend -> Filter mit Touched',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_transactions_with_contract: {
                'name': 'Anzahl Transaktionen mit Vertragsbezug',
                'description': 'Gesamtanzahl von Bestell-/Rechnungspositionen mit Bezug zu einem Vertrag.',
                'formula': '# Transaktionen -> Filter mit Vertragsbezug',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_spend_with_contract: {
                'name': 'Spend mit Vertragsbezug',
                'description': 'Gesamtvolumen von Bestell-/Rechnungswerten mit Bezug zu einem Vertrag.',
                'formula': '∑ Spend -> Filter mit Vertragsbezug',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_transactions_with_contract: {
                'name': 'Anteil Transaktionen mit Vertragsbezug',
                'description': 'Relativer Anteil von Bestell-/Rechnungspositionen mit Bezug zu einem Vertrag.',
                'formula': '(# Transaktionen -> Filter mit Vertragsbezug) / # Transaktionen',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_spend_with_contract: {
                'name': 'Anteil Spend mit Vertragsbezug',
                'description': 'Relativer Anteil des Bestell-/Rechnungsvolumens mit Bezug zu einem Vertrag.',
                'formula': '(∑ Spend -> Filter mit Vertragsbezug) / (∑ Spend)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_transactions_via_eprocurement: {
                'name': 'Anzahl Transaktionen über eProcurement',
                'description': 'Gesamtanzahl von Bestell-/Rechnungspositionen, die über eProcurement abgewickelt wurden.',
                'formula': '# Transaktionen -> Filter via eProcurement',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_spend_via_eprocurement: {
                'name': 'Spend über eProcurement',
                'description': 'Gesamtes Bestell-/Rechnungsvolumen, das über eProcurement abgewickelt wurde.',
                'formula': '∑ Spend -> Filter via eProcurement',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_transactions_via_eprocurement: {
                'name': 'Anteil Transaktionen über eProcurement',
                'description': 'Relativer Anteil von Bestell-/Rechnungspositionen, die über eProcurement abgewickelt wurden.',
                'formula': '(# Transaktionen -> Filter via eProcurement) / ( # Transaktionen)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_spend_via_eprocurement: {
                'name': 'Anteil Spend über eProcurement',
                'description': 'Relativer Anteil des Bestell-/Rechnungsvolumens, das über eProcurement abgewickelt wurde.',
                'formula': '(∑ Spend -> Filter via eProcurement) / (∑ Spend)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_transactions_via_catalog: {
                'name': 'Anzahl Transaktionen mit Katalogbezug',
                'description': 'Gesamtanzahl von Bestell-/Rechnungspositionen, die über Kataloge abgerufen wurden.',
                'formula': '# Transaktionen -> Filter via Katalog',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_spend_via_catalog: {
                'name': 'Spend mit Katalogbezug',
                'description': 'Bestell-/Rechnungsvolumen, das über Kataloge abgerufen wurde.',
                'formula': '∑ Spend -> Filter via Katalog',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_transactions_via_catalog: {
                'name': 'Anteil Transaktionen mit Katalogbezug',
                'description': 'Relativer Anteil von Bestell-/Rechnungspositionen, die über Kataloge abgerufen wurden.',
                'formula': '(# Transaktionen -> Filter via Katalog) / (# Transaktionen)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_spend_via_catalog: {
                'name': 'Anteil Spend mit Katalogbezug',
                'description': 'Relativer Anteil des Bestell-/Rechnungsvolumens, das über Kataloge abgerufen wurde.',
                'formula': '(∑ Spend -> Filter via Katalog) / (∑ Spend)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_average_spend_per_quantity: {
                'name': 'Durchschnittlicher Stückpreis',
                'description': 'Durchschnittlicher Einzelpreis pro Mengeneinheit.',
                'formula': ' Ø (Spend / Menge)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_transactions_with_delivery_on_time: {
                'name': 'Anteil Transaktionen mit rechtzeitiger Lieferung',
                'description': 'Relativer Anteil der Bestell-/Rechnungspositionen mit eingehaltenem Lieferdatum.',
                'formula': '(# Transaktionen -> Filter Lieferung rechtzeitig) / (# Transaktionen) ',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_spend_with_delivery_on_time: {
                'name': 'Anteil Spend mit rechtzeitiger Lieferung',
                'description': 'Relativer Anteil des Bestell-/Rechnungsvolumens mit eingehaltenem Lieferdatum.',
                'formula': '(∑ Spend -> Filter Lieferung rechtzeitig) / (∑ Spend)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_transactions_with_payment_on_time: {
                'name': 'Anteil Transaktionen mit rechtzeitiger Zahlung',
                'description': 'Relativer Anteil der Bestell-/Rechnungspositionen mit eingehaltener Rechnungsfälligkeit.',
                'formula': '(# Transaktionen -> Filter Zahlung rechtzeitig) / (# Transaktionen) ',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_share_spend_with_payment_on_time: {
                'name': 'Anteil Spend mit rechtzeitiger Zahlung',
                'description': 'Relativer Anteil des Bestell-/Rechnungsvolumens mit eingehaltener Rechnungsfälligkeit.',
                'formula': '(∑ Spend -> Filter Zahlung rechtzeitig) / (∑ Spend)',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'NONE',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            // individual KPI
            kpi_individual: {
                'name': 'Individuelle KPI 1',
                'description': 'tbd',
                'formula': 'tbd',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'INDIVIDUAL',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_procurement_costs: {
                'name': 'Kosten des Einkaufs',
                'description': '',
                'formula': '',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'INDIVIDUAL',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_s2c_costs: {
                'name': 'Kosten von S2C',
                'description': '',
                'formula': '',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'INDIVIDUAL',
                'requireFact': 'FALSE',
                'roundDigits': '0',
            },
            kpi_roi: {
                'name': 'Procurement Return on Invest (for each €)',
                'description': 'Diese Kennzahl gibt den Wirkungsgrad der Einkaufsabteilung an, sie setzt die Kosten des Einkaufs ins Verhältnis zu den generierten Savings.',
                'formula': 'Savings / Kosten des Einkaufs',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'INDIVIDUAL',
                'requireFact': 'FALSE',
                'roundDigits': '2',
            },
            kpi_roi_s2c: {
                'name': 'S2C Return on Invest (for each €)',
                'description': 'Diese Kennzahl gibt den Wirkungsgrad der S2C-Kostenstelle an, sie setzt die Kosten von S2C ins Verhältnis zu den generierten Savings.',
                'formula': 'Savings / Kosten der S2C-Kostenstelle',
                'customFrontendHandling': 'FALSE',
                'customFrontendHandlingType': 'INDIVIDUAL',
                'requireFact': 'FALSE',
                'roundDigits': '2',
            },
        },
        insightsInfo: {
            insight_spend_trend_vendor: {

            },
            insight_spend_trend_category_hier_1: {

            },
            insight_spend_trend_category_hier_2: {

            },
            insight_number_different_factories_per_vendor: {

            },
            insight_number_different_company_codes_per_vendor: {

            },
            insight_number_different_categories_hier_1_per_vendor: {

            },
            insight_number_different_categories_hier_2_per_vendor: {

            },
            insight_number_vendors_per_category_hier_1: {

            },
            insight_number_vendors_per_category_hier_2: {

            },
            insight_number_vendors_per_factory: {

            },
            insight_number_vendors_per_company_code: {

            },
            insight_spend_per_transaction_vendor: {

            },
            insight_spend_per_transaction_category_hier_1: {

            },
            insight_spend_per_transaction_category_hier_2: {

            },
            insight_diff_set_of_vendors_over_factories_for_category_hier_1: {

            },
            insight_diff_set_of_vendors_over_factories_for_category_hier_2: {

            },
            insight_diff_set_of_vendors_over_company_codes_for_category_hier_2: {

            },
            insight_diff_set_of_vendors_over_company_codes_for_category_hier_1: {

            },
        },
        cluster: {
            favourites: {
                'name': 'Favoriten',
                'icon': 'mdi-star-outline',
            },
            orga_favourites: {
                'name': 'Gruppen-Favoriten',
                'name___utdi': 'Warengruppen-Dossier',
                'icon': 'mdi-star-settings',
            },
            general: {
                'name': 'Allgemein',
                'icon': 'mdi-view-compact-outline',
            },
            maverick_buying: {
                'name': 'Maverick-Buying',
                'icon': 'mdi-alpha-x-circle-outline',
            },
            count: {
                'name': 'Anzahl',
                'icon': 'mdi-abacus',
            },
            time: {
                'name': 'Zeit',
                'icon': 'mdi-timer-sand',
            },
            skonto: {
                'name': 'Skonto',
                'icon': 'mdi-cash-refund',
            },
            budget: {
                'name': 'Budget',
                'icon': 'mdi-cash',
            },
            contract: {
                'name': 'Vertrag',
                'icon': 'mdi-file-document-edit-outline',
            },
            eprocurement: {
                'name': 'eProcurement',
                'icon': 'mdi-monitor',
            },
            catalog: {
                'name': 'Katalog',
                'icon': 'mdi-book-open-page-variant-outline',
            },
            quantity: {
                'name': 'Menge',
                'icon': 'mdi-format-line-weight',
            },
            backlog: {
                name: 'Andere KPI',
                icon: 'mdi-dots-horizontal',
            },
            individual: {
                name: 'Individuelle KPI',
                icon: 'mdi-dots-horizontal',
            },
            all: {
                'name': 'Alle anzeigen',
                'icon': 'mdi-notification-clear-all',
            },
        },
        legend: {
            very_good: "über Zielwert",
            good: "über Zielwert, aber mit Risiko bald unter das Ziel zu fallen",
            bad: "unter Zielwert, aber mit Chance das Ziel bald zu erreichen",
            very_bad: "unter Zielwert",
            undefined: "noch kein Zielwert vorhanden oder Performance kann nicht berechnet werden",
        },
    },
    savings: {
        valueTypes: {
            spend_managed: 'Managed Volume',
            spend_awarded: 'Awarded Volume',
            spend_realized: 'Realized Volume',
            spend_savings: 'Savings',
            spend_savings_reduction: 'Cost Reduction',
            spend_savings_avoidance: 'Cost Avoidance',
            spend_savings_missed: 'Missed Savings',
            spend_savings_opex: 'OpEx',
            spend_savings_capex: 'CapEx'
        },
        module: {
            negotiationResult: 'Negotiation Result',
            negotiationResultDef: 'Der Savings-Wert wird ohne Periodenabgrenzung ausgewiesen, also im vollen Wert zum Zeitpunkt des Starts des Vertrags',
            negotiationResultDef___utdi: 'Periodenunabhängige Summe aller von CP verantworteten Cost Reduction und Cost Avoidance Initiativen',
            implementedSavings: 'Implemented Savings',
            implementedSavingsDef: 'Der Savings-Wert wird mit Periodenabgrenzung ausgewiesen, also ab dem Zeitpunkt des Starts des Vertrags auf die Monate der Vertragslaufzeit aufgeteilt',
            implementedSavingsDef___utdi: 'Summe aller verhandelten Einsparungen zum Zeitpunkt des Vertragsschlusses; über die gesamte Vertragslaufzeit; kalkuliert wird mit prognostizierten Mengen',
            managedVolume: 'Managed Volume',
            managedVolumeDef: 'Gibt das verhandelte Volumen an, also den Wert ohne Savings und Einfluss des Einkaufs',
            managedVolumeDef___utdi: 'Einkaufsvolumen, vor dem Beginn der Verhandlungen',
            savingsShare: 'Savings Anteil vom Managed Volume',
            savingsShareDef: 'Gibt den Anteil der Savings an dem Managed Volume in Prozent an',
            countProjects: 'Anzahl Projekte',
            countProjectsDef: 'Anzahl der im ausgewählten Zeitraum durchgeführten Projekte',
            countProjectsDef___utdi: 'Projekte auf Grundlage von Sourcing Aktivitäten',
            costAvoidanceDef: 'Der Anteil, der auf eine Kostenvermeidung entfallen ist. Bei einer Kostenvermeidung liegt der Einkaufserfolg in der Kostenreduktion zum ersten Angebot oder zur Preiserhöhung eines Lieferanten.',
            costAvoidanceDef___utdi: 'Periodenabhängige Abwendung von Kostensteigerungen und Reduzierung von nicht budegetierten Kosten aus neuen Bedarfen, häufig auch als „Soft Savings“ bezeichnet',
            costReductionDef: 'Der Anteil, der auf eine Kostenreduktion entfallen ist. Eine Kostenreduktion liegt vor, wenn bei einem bestehenden Bedarf die Kosten unter den vorherigen Wert gesenkt werden.',
            costReductionDef___utdi: 'Periodenabhängige  Preis- bzw. Kostensenkung bestehender Kosten relativ zur Vorperiode, häufig auch als „Hard Savings“ bezeichnet',
        },
        projects: {
            headline: 'Savings-Projekte',
            create: 'Neues Savings-Projekt anlegen',
            edit: 'Savings-Projekt bearbeiten',
            created_by: 'Angelegt von',
            status: 'Status',
            name: 'Name',
            timestamp_contract_signature_date: 'Datum Vertragsschluss',
        }
    }
}
