import { 
    readHasAdminRole,
    readHasSuperUserRole,
    readHasPurchaserRole,
    readHasConsumerRole,
    readHasResearchAccess,
    readHasClusterAccess,
    readHasCategorizerAccess,
    readHasCategorizerAggregatedAccess,
    readHasCategoryAPIAccess,
    readHasInsightsAccess,
    readHasKPIAccess,
    readHasReportsAccess,
    readHasSavingsAccess,
    readHasLLMAccess,
    readHasManagementRole,
} from '@/store/main/getters';

import { apiUrlSocket } from '@/env';
import de from '@/translations/de';

export const getLocalToken = () => localStorage.getItem('token');

export const saveLocalToken = (token: string) => localStorage.setItem('token', token);

export const removeLocalToken = () => localStorage.removeItem('token');

export const hasRole = (context: any, requiredRole: string) => {
    if (requiredRole === '') {
        return true;
    } else if (requiredRole === 'SUPERUSER') {
        return readHasSuperUserRole(context);
    } else if (requiredRole === 'ADMIN') {
        return (
            readHasSuperUserRole(context) ||
            readHasAdminRole(context)
        );
    } else if (requiredRole === 'MANAGEMENT') {
        return (
            readHasSuperUserRole(context) ||
            readHasAdminRole(context) ||
            readHasManagementRole(context)
        );
    } else if (requiredRole === 'PURCHASER') {
        return (
            readHasSuperUserRole(context) ||
            readHasAdminRole(context) ||
            readHasManagementRole(context) ||
            readHasPurchaserRole(context)
        );
    } else if (requiredRole === 'CONSUMER') {
        return (
            readHasSuperUserRole(context) ||
            readHasAdminRole(context) ||
            readHasManagementRole(context) ||
            readHasPurchaserRole(context) ||
            readHasConsumerRole(context)
        );
    } else {
        return false;
    }
}

export const hasAccess = (context: any, requiredAccess: string) => {
    if (requiredAccess === '') {
        return true;
    } else if (requiredAccess === 'RESEARCH') {
        return readHasResearchAccess(context);
    } else if (requiredAccess === 'CLUSTER') {
        return readHasClusterAccess(context);
    } else if (requiredAccess === 'CATEGORIZER') {
        return readHasCategorizerAccess(context);
    } else if (requiredAccess === 'CATEGORIZER_AGGREGATED') {
        return readHasCategorizerAggregatedAccess(context);
    } else if (requiredAccess === 'CATEGORY_API') {
        return readHasCategoryAPIAccess(context);
    } else if (requiredAccess === 'INSIGHTS') {
        return readHasInsightsAccess(context);
    } else if (requiredAccess === 'KPI') {
        return readHasKPIAccess(context);
    } else if (requiredAccess === 'REPORTS') {
        return readHasReportsAccess(context);
    } else if (requiredAccess === 'SAVINGS') {
        return readHasSavingsAccess(context);
    } else if (requiredAccess === 'LLM') {
        return readHasLLMAccess(context);
    } else {
        return false;
    }
}

export const getAPIUrl = () => {
    let url = '';
    if (process.env.NODE_ENV === 'development') {
        url = `wss://${process.env.VUE_APP_DOMAIN_STAG}`;
        // url = apiUrlSocket;
    } else {
        url = apiUrlSocket;
    }
    return url;
}


export const getInformationFromCategoryObject = (categoryObject: { [key: string]: any }, informationKey: string, lowestHir = true, customHir = NaN) => {

    let currentObject = categoryObject;

    while (currentObject && !(informationKey in currentObject)) {
        if (!lowestHir || !currentObject['child']) {
            break;

        }

        currentObject = currentObject['child'];
    }

    return currentObject ? currentObject[informationKey] : undefined;
}

export const isEqual = (value: any, other: any) => {

    // Get the value type
    const type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) return false;

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    // Compare the length of the length of the two items
    const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    // Compare two items
    const compare = function (item1: any, item2: any) {

        // Get the object type
        const itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
            if (!isEqual(item1, item2)) return false;
        }

        // Otherwise, do a simple comparison
        else {

            // If the two items are not the same type, return false
            if (itemType !== Object.prototype.toString.call(item2)) return false;

            // Else if it's a function, convert to a string and compare
            // Otherwise, just compare
            if (itemType === '[object Function]') {
                if (item1.toString() !== item2.toString()) return false;
            } else {
                if (item1 !== item2) return false;
            }

        }
    };

    // Compare properties
    if (type === '[object Array]') {
        for (let i = 0; i < valueLen; i++) {
            if (compare(value[i], other[i]) === false) return false;
        }
    } else {
        for (const key in value) {
            if (Object.prototype.hasOwnProperty.call(value, key)) {
                if (compare(value[key], other[key]) === false) return false;
            }
        }
    }

    // If nothing failed, return true
    return true;

};

export const hasOrgaSpecificText = (lookup: string, orga: string) => {
    function has(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }
    return has(de, `${lookup}___${orga}`);
};

